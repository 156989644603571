/* eslint-disable react/display-name */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyUser = localStorage.getItem('@admin');
    const parsedUser = stringifyUser ? JSON.parse(stringifyUser) : null;

    useEffect(() => {
      if (!parsedUser) {
        navigate('/login');
        // return null;
      }
    }, [navigate, parsedUser]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
