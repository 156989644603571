import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { Spin } from 'antd';
import PortfolioCard from './PortfolioCard';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { useGetPropertiesQuery } from '../../hooks/api/Portfolio/useGetPropertiesQuery';
import { FiUploadCloud } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: properties, isFetching } = useGetPropertiesQuery(
    currentPage,
    15,
    searchParam
  );

  const pages = Array.from(
    { length: properties?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span className="flex gap-8 items-center">
          <span>
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold">Portfolio</span>
              {properties && (
                <span className="bg-[#4D4D94] text-white text-xs p-1 rounded-md">
                  {properties?.data?.total}
                </span>
              )}
            </div>
            <p className="text-gray mt-2">View Portfolio</p>
          </span>
          <Link
            to={`/portfolio/upload`}
            className="px-4 mx-auto bg-blue text-white py-4 rounded-md cursor-pointer mt-4 flex items-center gap-3"
          >
            Upload Property <FiUploadCloud />
          </Link>
        </span>
        <input
          type="text"
          placeholder="Search portfolio name"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      {properties?.data?.total === 0 && (
        <p className="text-center text-gray ">
          No data available for this search
        </p>
      )}
      <div className="grid grid-cols-3 gap-5 h-[65vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {!isFetching &&
          properties?.data.data &&
          properties?.data.data.map((payload) => (
            <div key={payload.id}>
              <PortfolioCard payload={payload} />
            </div>
          ))}
      </div>
      {properties?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {properties?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={properties?.data?.current_page === 1 ? true : false}
              className={` ${
                properties?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? ' cursor-not-allowed bg-blue text-white'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === properties?.data?.last_page}
              className={`${
                currentPage === properties?.data?.last_page ? 'opacity-50' : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Portfolio;
