import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import BackButton from '../../components/BackButton';
import { useGetRefundsQuery } from '../../hooks/api/Refund/useGetRefundsQuery';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { Spin } from 'antd';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

type Props = {};

const Refunds = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: refunds, isFetching } = useGetRefundsQuery();

  const pages = Array.from(
    { length: refunds?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}

      <div className="flex gap-2 items-center mb-2 font-bold bg-white p-2">
        <div className="w-3/12">Tenant Name</div>
        <div className="w-5/12">Property in view</div>
        <div className="w-2/12">Rating</div>
        <div className="w-1/12">Date</div>
        <div className="w-1/12">Status</div>
        <div className="w-1/12"></div>
      </div>
      <div className="h-[60vh] overflow-y-scroll">
        {!isFetching &&
          refunds?.data?.data.map(
            ({ id, application, listing, createdAt, status, landlord }) => (
              <div
                key={id}
                className="flex gap-2 items-center text-sm my-2 pb-2 border-b-[1px] border-[#ddd] p-2"
              >
                <div className="w-3/12">
                  <div className="flex gap-2 items-center">
                    <img src={application?.tenant?.image} alt="" />
                    <span>
                      <h3 className="font-bold">
                        {application?.tenant?.firstName +
                          landlord?.tenant?.lastName}
                      </h3>
                      <p className="text-gray">
                        {application?.tenant?.occupation}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="w-5/12">
                  <h3 className="">{listing?.propertyType}</h3>
                  <p className="text-gray text-xs">
                    {listing?.addressLine1 && listing?.addressLine1 + ', '}
                    {listing?.city && listing?.city + ', '}
                    {listing?.state && listing?.state}
                  </p>
                </div>
                <div className="w-2/12 text-gray">
                  {JSON.stringify(listing?.landlord)}
                </div>
                <div className="w-1/12 text-gray">
                  <Moment format="YYYY/MM/DD" interval={0}>
                    {createdAt}
                  </Moment>
                </div>
                <div className="w-1/12 font-md">{status}</div>
                <div className="w-1/12">
                  <Link
                    to={`/refunds/${id}`}
                    className="px-8 block bg-blue text-white py-2 rounded-md cursor-pointer text-sm"
                  >
                    View
                  </Link>
                </div>
              </div>
            )
          )}
      </div>

      {refunds?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {refunds?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={refunds?.data?.current_page === 1 ? true : false}
              className={` ${
                refunds?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? ' cursor-not-allowed bg-blue text-white'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === refunds?.data?.last_page}
              className={`${
                currentPage === refunds?.data?.last_page ? 'opacity-50' : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Refunds;
