import React from 'react';
import {
  Chart as ChartJS,
  Filler,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { GoDotFill } from 'react-icons/go';
import { Spin } from 'antd';
import { NumericFormat } from 'react-number-format';

ChartJS.register(ArcElement, Tooltip, Legend, Tooltip, Filler, Legend);

const PropertyAnalyticsCharts = ({ data, loading }) => {
  const chartData = {
    // labels,
    datasets: [
      {
        label: 'Count',
        data: [
          data?.data?.properties?.availableListing,
          data?.data?.properties?.occupiedListing,
          data?.data?.properties?.rejectedApplication,
        ],
        backgroundColor: ['#CC9933', '#000066', '#d0163ee0'],
        borderColor: ['#CC9933', '#000066', '#d0163ee0'],
        borderWidth: 2,
      },
    ],
  };

  const titleStyle = 'text-sm text-gray';
  const bodyStyle = 'font-bold text-xl';

  return (
    <div className="w-6/12 bg-white p-3 rounded-md shadow-sm">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold">Property Analysis</h3>
        <span>
          {loading && <Spin size="small" />}
          <h3 className="font-bold text-right">
            {data?.data?.properties &&
              data?.data?.properties?.availableListing +
                data?.data?.properties?.occupiedListing +
                data?.data?.properties?.rejectedApplication}
          </h3>
          <p className="text-sm text-gray">Total Listings</p>
        </span>
      </div>
      {loading && (
        <div className="h-[250px]">
          <Spin size="large" className="text-center" />
        </div>
      )}
      {!loading && (
        <div className="h-[250px] flex gap-3 items-center">
          <Doughnut data={chartData} />
          <span>
            <div className="flex gap-4 mb-3">
              <span className="h-[10px] w-[10px] text-[#000066] mt-[1px]">
                <GoDotFill className="text-xl" />
              </span>
              <div>
                <h3 className={titleStyle}>Available Listings</h3>
                <NumericFormat
                  value={data?.data?.properties?.availableListing}
                  thousandSeparator=","
                  className={bodyStyle}
                  displayType="text"
                />
              </div>
            </div>
            <div className="flex gap-4 mb-3">
              <span className="h-[10px] w-[10px] text-[#CC9933] mt-[2px]">
                <GoDotFill className="text-xl" />
              </span>
              <div>
                <h3 className={titleStyle}>Occupied Listings</h3>
                <NumericFormat
                  value={data?.data?.properties?.occupiedListing}
                  thousandSeparator=","
                  className={bodyStyle}
                  displayType="text"
                />
              </div>
            </div>
            <div className="flex gap-4 mb-3">
              <span className="h-[10px] w-[10px] text-[#d0163ee0] mt-[2px]">
                <GoDotFill className="text-xl" />
              </span>
              <div>
                <h3 className={titleStyle}>Rejected Applications</h3>
                <NumericFormat
                  value={data?.data?.properties?.rejectedApplication}
                  thousandSeparator=","
                  className={bodyStyle}
                  displayType="text"
                />
              </div>
            </div>
          </span>
        </div>
      )}
    </div>
  );
};

export default PropertyAnalyticsCharts;
