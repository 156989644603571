import React from 'react';
// import { useApproveListingMutation } from '../../../hooks/api/Listing/useApproveListingMutation';
import { useRejectListingMutation } from '../../../hooks/api/Listing/useRejectListingMutation';

type Props = { id: string; handleCancel: any };

const DeclineModal = ({ id, handleCancel }: Props) => {
  const Cross = require('../../../assets/cross.png');

  const { mutateAsync: declineListing } = useRejectListingMutation();

  const handleApprove = () => {
    declineListing(id, { onSuccess: handleCancel() });
  };

  return (
    <div>
      <img src={Cross} alt="flag icon" className="flex mx-auto mt-10" />
      <div className="text-center mt-2 text-black text-2xl font-bold">
        DECLINE PROPERTY VERIFICATION
      </div>
      <p className="text-center text-gray mb-3">
        Declining an application is a final action.
      </p>
      <p className="text-center text-gray">Confirm only if intentional.</p>
      <p className="text-center text-gray my-3 font-bold">
        Are you sure you want to continue?
      </p>
      <div className="flex mt-3 justify-center gap-4">
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
          onClick={() => handleApprove()}
        >
          Yes
        </button>
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
          onClick={handleCancel}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default DeclineModal;
