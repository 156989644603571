import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { Spin, Table } from 'antd';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { useGetTransactionsQuery } from '../../hooks/api/Transactions/useGetTransactionsQuery';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { getStatusColor } from '../../utils/getStatusColor';
import Moment from 'react-moment';

const Transactions = () => {
  // const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const Avatar = require('../../assets/avatar.jpg');

  const { data: transactions, isFetching } = useGetTransactionsQuery(1, 15, '');

  const pages = Array.from(
    { length: transactions?.data?.last_page },
    (_, index) => index + 1
  );

  const columns = [
    {
      title: 'Sender',
      label: 'Sender',
      render: (payload) => {
        return (
          <Link
            to={`/transactions/${payload?.id}`}
            className="flex gap-2 items-center text-xs"
          >
            <img
              src={
                payload?.wallet?.image === '' ? Avatar : payload?.wallet?.image
              }
              alt=""
              className="h-10  w-10 rounded-full"
            />
            <div>
              <h3 className="text-black font-bold">
                {payload?.wallet?.firstName + ' ' + payload?.wallet?.lastName}
              </h3>
              <p className="text-gray">{payload?.wallet?.email}</p>
            </div>
          </Link>
        );
      },
      width: '20%',
    },
    {
      title: 'Beneficiary',
      dataIndex: 'recipient',
      key: 'recipient',
      render: (recipient) => {
        return <span className="text-gray text-xs">{recipient}</span>;
      },
      width: '15%',
    },
    {
      title: 'Payment ID',
      dataIndex: 'reference',
      key: 'reference',
      render: (reference) => {
        return <span className="text-gray text-xs">{reference}</span>;
      },
      width: '20%',
    },
    {
      title: 'Description',
      dataIndex: 'title',
      key: 'title',
      render: (title) => {
        return <span className="text-gray text-xs">{title}</span>;
      },
      width: '15%',
    },
    {
      title: 'Amount',
      render: (payload) => {
        return (
          <div className="flex gap-2">
            <NumericFormat
              value={payload?.balanceBefore - payload?.balanceAfter}
              prefix="&#8358;"
              thousandSeparator=","
              className="text-gray"
              displayType="text"
            />
          </div>
        );
      },
      width: '10%',
    },
    {
      title: 'Status',
      render: (payload) => {
        return (
          <p className={getStatusColor(payload?.status)}>{payload?.status}</p>
        );
      },
      width: '10%',
    },
    {
      title: 'Date',
      render: (payload) => {
        return (
          <Moment format="DD/MM/YYYY" interval={0} className="text-xs">
            {payload.createdAt}
          </Moment>
        );
      },
      width: '10%',
    },
  ];

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold">Transactions</span>

            {transactions && (
              <span className="bg-[#4D4D94] text-white text-xs p-1 rounded-md">
                {transactions?.data?.total}
              </span>
            )}
          </div>
          <p className="text-gray mt-2">Recent Transactions</p>
        </span>
        <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          // onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      <div className=" gap-5 h-[65vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {transactions?.data.data && (
          <Table
            dataSource={transactions?.data.data}
            columns={columns}
            scroll={{ y: '50vh' }}
          />
        )}
      </div>
      {transactions?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {transactions?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={transactions?.data?.current_page === 1 ? true : false}
              className={` ${
                transactions?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? ' cursor-not-allowed bg-blue text-white'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === transactions?.data?.last_page}
              className={`${
                currentPage === transactions?.data?.last_page
                  ? 'opacity-50'
                  : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Transactions;
