import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useRejectListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) =>
      axiosInstance
        .post(`${apiConfig.LISTINGS}/${id}/reject`)
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success(data?.message);
        console.log('✅ REJECTED LISTING');
        queryClient.invalidateQueries('getOneListing');
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        console.log('❌ REJECTED LISTING');
      },
    }
  );
};
