import React, { useState } from 'react';
import Header from '../../components/Header';
import DashboardLayout from '../../components/DashboardLayout';
import BackButton from '../../components/BackButton';
import { MdOutlineEmail } from 'react-icons/md';
import { useGetMeQuery } from '../../hooks/api/Admin/useGetMeQuery';
import { Spin } from 'antd';
import { RiUserForbidLine } from 'react-icons/ri';
import { BsFlag } from 'react-icons/bs';
import { useChangeAdminPasswordMutation } from '../../hooks/api/Admin/useChangeAdminPasswordMutation';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = {};

const Profile = (props: Props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [visibility1, setVisibility1] = useState(true);
  const [visibility, setVisibility] = useState(true);

  const isSubmitDisabled = () => {
    return (
      oldPassword.trim() === '' ||
      newPassword.trim() === '' ||
      newPassword2.trim() === '' ||
      newPassword !== newPassword2 ||
      newPassword.length <= 5 ||
      newPassword2.length <= 5 ||
      oldPassword.length <= 5
    );
  };

  const { mutateAsync: changeAdminPassword } = useChangeAdminPasswordMutation(
    oldPassword,
    newPassword
  );
  const { data: me, isLoading } = useGetMeQuery();

  const Avatar = require('../../assets/avatar.jpg');

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      {isLoading && <Spin className="mx-auto block h-full mt-[30vh]" />}
      <div className="flex gap-3">
        <div className="w-4/12">
          <div className="bg-white rounded-md p-4">
            <h3 className="text-xl font-bold mb-3">Profile</h3>
            <div className="flex gap-3">
              <img
                src={Avatar}
                className="rounded-full border-[2px] border-blue h-[40px] w-[40px]"
                alt="avatar"
              />

              <span>
                <h3 className="font-bold text-lg text-black">
                  {me?.data?.firstName ? me?.data?.firstName : '--'}{' '}
                  {me?.data?.lastName ? me?.data?.lastName : '--'}
                </h3>
              </span>
            </div>

            <div className="flex gap-3 items-center my-3">
              <MdOutlineEmail className="text-xl" />
              <span className="text-gray">{me?.data?.email}</span>
            </div>
            <div className="flex gap-3 my-3 items-center">
              <RiUserForbidLine className="text-xl" />
              <span className="text-gray text-md">
                {me?.data?.suspendedLandlord
                  ? me?.data?.suspendedLandlord + me?.data?.suspendedTenant
                  : 0}{' '}
                Suspended Account
                {`${
                  me?.data?.suspendedLandlord + me?.data?.suspendedTenant > 1
                    ? 's'
                    : ''
                }`}
              </span>
            </div>

            <div className="flex gap-3 items-center">
              <BsFlag className="text-xl" />
              <span className="text-gray text-md">
                {me?.data?.flaggedTenant
                  ? me?.data?.flaggedTenant + me?.data?.flaggedLandlord
                  : 0}{' '}
                Flagged Account
                {`${
                  me?.data?.flaggedTenant + me?.data?.flaggedLandlord > 1
                    ? 's'
                    : ''
                }`}
              </span>
            </div>
          </div>
        </div>

        <div className="w-4/12">
          <div className="bg-white rounded-md p-4">
            <h3 className="text-xl font-bold mb-3">Password</h3>
            <label htmlFor="oldpassword" className="font-gray">
              Old Password
            </label>

            <input
              name="oldpassword"
              type="password"
              placeholder="Old Password"
              className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
            />

            <label htmlFor="oldpassword" className="font-gray">
              New Password
            </label>

            <div className="relative">
              <input
                name="newpassword"
                type={visibility1 === true ? 'password' : 'text'}
                placeholder="New Password"
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
              <span
                className="text-[24px] text-gray-500 cursor-pointer absolute right-2 top-7"
                onClick={() => setVisibility1(!visibility1)}
              >
                {visibility1 === true ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </span>
            </div>

            <label htmlFor="confirmpassword" className="font-gray">
              Confirm Password
            </label>
            <div className="relative">
              <input
                name="confirmpassword"
                type={visibility === true ? 'password' : 'text'}
                placeholder="Confirm Password"
                className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
                onChange={(e) => setNewPassword2(e.target.value)}
                value={newPassword2}
              />

              <span
                className="text-[24px] text-gray-500 cursor-pointer absolute right-2 top-7"
                onClick={() => setVisibility(!visibility)}
              >
                {visibility === true ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </span>
            </div>

            <button
              type="submit"
              disabled={isSubmitDisabled()}
              style={{ opacity: isSubmitDisabled() ? 0.5 : 1 }}
              className="px-4 block mx-auto w-full bg-blue text-white py-5 rounded-md cursor-pointer mt-4"
              onClick={() => {
                changeAdminPassword();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;
