import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetMeQuery = () => {
  return useQuery({
    queryKey: 'getme',
    queryFn: () => axiosInstance.get(`${apiConfig.ME}`).then((res) => res.data),
    onSuccess: (data) => {
      localStorage.setItem('@me', JSON.stringify(data?.data));
      if (data.data.roles.includes('super-admin')) {
        localStorage.setItem('@user', 'super-admin');
      } else if (data.data.roles.includes('admin')) {
        localStorage.setItem('@user', 'admin');
      } else if (data.data.roles.includes('inspector')) {
        localStorage.setItem('@user', 'inspector');
      } else {
        localStorage.setItem('@user', 'null');
      }
    },
    onError: (error) => {
      console.log('❌ My Data');
    },
  });
};
