import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { useGetTenantsQuery } from '../../hooks/api/Tenants/useGetTenantsQuery';
import { Spin } from 'antd';
import TenantCard from './TenantCard';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

const Tenants = () => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: tenants, isFetching } = useGetTenantsQuery(
    currentPage,
    9,
    searchParam
  );

  const pages = Array.from(
    { length: tenants?.data?.last_page },
    (_, index) => index + 1
  );

  type TenantCardProps = {
    id: string;
    email: string;
    image: string;
    isEmailVerified: boolean;
    isProfileComplete: boolean;
    suspend: boolean;
    isFlagged: boolean;
    deleted: boolean;
    roles: string[];
    firstName: string;
    lastName: string;
    lga: string;
    moreDescription: string;
    nextOfKinName: string;
    nextOfKinPhone: string;
    occupation: string;
    phone: string;
    state: string;
    otp?: any;
  };

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold">Tenants</span>

            {tenants && (
              <span className="bg-[#4D4D94] text-white text-xs p-1 rounded-md">
                {tenants?.data?.total}
              </span>
            )}
          </div>
          <p className="text-gray mt-2">
            View Tenant{tenants?.data?.total > 1 && 's'}
          </p>
        </span>
        <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      {tenants?.data?.total === 0 && (
        <p className="text-center text-gray ">
          No data available for this search
        </p>
      )}
      <div className="grid grid-cols-3 gap-5 h-[65vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {tenants?.data.data.map((payload: TenantCardProps) => (
          <div key={payload.id}>
            <TenantCard payload={payload} />
          </div>
        ))}
      </div>
      {tenants?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {tenants?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={tenants?.data?.current_page === 1 ? true : false}
              className={` ${
                tenants?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? 'bg-blue text-white cursor-not-allowed'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === tenants?.data?.last_page}
              className={`${
                currentPage === tenants?.data?.last_page ? 'opacity-50' : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Tenants;
