import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { useGetTenantApplicationsQuery } from '../../hooks/api/Application/useGetTenantApplicationsQuery';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { Spin } from 'antd';
import Application from './Application';

type Props = {};

const Listings = (props: Props) => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: applications, isFetching } = useGetTenantApplicationsQuery(
    currentPage,
    15,
    searchParam
  );

  const pages = Array.from(
    { length: applications?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold">Tenant Application</span>
            {applications?.data?.total && (
              <span className="bg-[#4D4D94] text-white text-sm p-1 rounded-md">
                {applications?.data?.total}
              </span>
            )}
          </div>
          <p className="text-gray mt-2">All Tenant Applications</p>
        </span>
        <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100 hidden"
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}{' '}
      {applications?.data?.total === 0 && (
        <p className="text-center text-gray ">
          No data available for this search
        </p>
      )}
      <div className="flex gap-2 items-center mb-2 font-bold bg-white p-2">
        <div className="w-3/12">Tenant Name</div>
        <div className="w-4/12">Property in view</div>
        <div className="w-2/12">Rating</div>
        <div className="w-2/12">Date Submitted</div>
        <div className="w-1/12">Status</div>
        <div className="w-1/12">Option</div>
      </div>
      <div className="h-[50vh] overflow-y-scroll bg-white">
        {applications?.data?.data.map(
          ({ id, tenant, listing, createdAt, status }) => (
            <div key={id}>
              <Application
                id={id}
                tenant={tenant}
                listing={listing}
                createdAt={createdAt}
                status={status}
              />
            </div>
          )
        )}
      </div>
      {applications?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {applications?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={applications?.data?.current_page === 1 ? true : false}
              className={` ${
                applications?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? ' cursor-not-allowed bg-blue text-white'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === applications?.data?.last_page}
              className={`${
                currentPage === applications?.data?.last_page
                  ? 'opacity-50'
                  : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Listings;
