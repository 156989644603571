import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FinancialAnalyticsCharts = () => {
  const options = {
    responsive: true,
  };

  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Deposit',
        data: [
          7500000, 19000000, 80000000, 95000000, 100000000, 95003400, 80506000,
          19000000, 19000000, 85000000, 9500000, 80000000,
        ],
        backgroundColor: '#388c38',
        borderColor: '#388c38',
      },
      {
        label: 'Refund',
        data: [
          2500000, 1900000, 8000000, 9500000, 10000000, 9503400, 8050600,
          3800000, 4000000, 200000, 4000000, 6000000,
        ],
        backgroundColor: '#d0163ee0',
        borderColor: '#d0163ee0',
      },
      {
        label: 'Rent',
        data: [
          9500000, 49000000, 90000000, 105000000, 100000000, 85003400, 90506000,
          19000000, 19000000, 19000000, 19000000, 90000000,
        ],
        backgroundColor: '#000066',
        borderColor: '#000066',
      },
    ],
  };

  return (
    <div className="w-6/12 bg-white p-3 rounded-md shadow-sm">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold">Financial Analysis</h3>
        <span>
          <h3 className="font-bold text-right">2,000,000,000</h3>
          <p className="text-sm text-gray">Total revenue</p>
        </span>
      </div>
      <div className="h-[250px]">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};

export default FinancialAnalyticsCharts;
