import React, { useState } from 'react';
import { BsBank, BsHouses, BsTelephone } from 'react-icons/bs';
import { MdVerified } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';
import { Modal, Tooltip } from 'antd';
import { useGetLandlordListingsQuery } from '../../hooks/api/Landlords/useGetLandlordListingsQuery';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import { useGetOneLandlordQuery } from '../../hooks/api/Landlords/useGetOneLandlordQuery';
import SuspendLandlord from './SuspendLandlord';
import FlagLandlord from './FlagLandlord';
import BackButton from '../../components/BackButton';
import LandlordListingCard from './LandlordListingCard';
import toast from 'react-hot-toast';
import LoadingComponent from '../../components/LoadingComponent';

const LandlordPreview = () => {
  const { id } = useParams();
  const { data: landlordListings } = useGetLandlordListingsQuery(id);
  const { data: landlord, isFetching } = useGetOneLandlordQuery(id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleAction = (record, title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const Avatar = require('../../assets/avatar.jpg');

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(landlord?.data?.accountNumber);
      toast.success('Account Number Copied');
    } catch (err) {
      console.error('Error copying to Account Number:', err);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <Header>
          <BackButton />
        </Header>

        <Modal
          title={
            content === 'listing' && (
              <div>
                <span className="text-xl font-bold">Current Situation</span>
                <p className="text-gray text-sm">
                  Manage Tenant Housing Situation
                </p>
              </div>
            )
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={content === 'listing' ? 1000 : 400}
        >
          {content === 'suspend' && (
            <SuspendLandlord
              id={id}
              status={landlord?.data?.suspend}
              handleCancel={handleCancel}
            />
          )}
          {content === 'flag' && (
            <FlagLandlord
              id={id}
              status={landlord?.data?.isFlagged}
              handleCancel={handleCancel}
            />
          )}
          {content === 'listing' && (
            <div className="mt-5">
              <div className="grid grid-cols-3 gap-3">
                {landlordListings.data.data.map(
                  ({
                    id,
                    propertyType,
                    addressLine1,
                    city,
                    state,
                    annualRent,
                    noOfLivingRoom,
                    noOfBedrooms,
                    noOfBathrooms,
                    landlord,
                    status,
                    images,
                  }) => (
                    <Link to={`/listings/${id}`} key={id}>
                      <LandlordListingCard
                        id={id}
                        propertyType={propertyType}
                        addressLine1={addressLine1}
                        city={city}
                        state={state}
                        annualRent={annualRent}
                        noOfLivingRoom={noOfLivingRoom}
                        noOfBedrooms={noOfBedrooms}
                        noOfBathrooms={noOfBathrooms}
                        landlord={landlord}
                        status={status}
                        images={images}
                      />
                    </Link>
                  )
                )}
              </div>
            </div>
          )}
        </Modal>

        {isFetching && <LoadingComponent />}
        {landlord && !isFetching && landlordListings && (
          <div className="flex gap-4">
            <div className="w-5/12 rounded-md p-4 bg-white">
              <img
                src={
                  landlord?.data?.image !== '' ? landlord?.data?.image : Avatar
                }
                alt="avatar"
                className="h-[80px] w-[80px] rounded-full mx-auto"
              />
              <div className="text-center text-2xl font-bold flex gap-2 items-center justify-center">
                {landlord?.data?.firstName ? landlord?.data?.firstName : '--'}{' '}
                {landlord?.data?.lastName ? landlord?.data?.lastName : '--'}
                {landlord?.data?.isProfileComplete && (
                  <Tooltip title="Email is verified">
                    <MdVerified className="text-sky-500 cursor-pointer" />
                  </Tooltip>
                )}
              </div>

              <div className="flex gap-3 mb-5 mt-3">
                <BsHouses className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Current Situation</h4>
                  <span className="text-stone-500">{'--'}</span>
                </span>
              </div>
              {landlordListings?.data?.total > 0 && (
                <button
                  className="bg-blue text-white px-6 py-2 block mx-auto my-3 rounded-md text-sm "
                  onClick={() => handleAction(id, 'listing')}
                >
                  View All
                </button>
              )}
              <p>Bio</p>
              <textarea
                name=""
                id=""
                cols={30}
                rows={3}
                className="text-black bg-ash p-4 resize-none rounded-md w-full mb-3"
                value={
                  landlord?.data?.moreDescription
                    ? landlord?.data?.moreDescription
                    : '--'
                }
                disabled={true}
              />
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <div className="flex gap-3 mb-5 mt-3">
                <FiMail className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Email Address</h4>
                  <span className="text-stone-500">
                    {landlord?.data?.email ? landlord?.data?.email : '--'}
                  </span>
                </span>
              </div>
              <div className="flex gap-3 mb-5 mt-3">
                <BsTelephone className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Phone Number</h4>
                  <span className="text-stone-500">
                    {landlord?.data?.phone ? landlord?.data?.phone : '--'}
                  </span>
                </span>
              </div>
              <div
                className="flex gap-3 items-top mt-2"
                onClick={handleCopyClick}
              >
                <BsBank className="mt-1 " />
                <div className="text-gray text-md">
                  <div className="black1">
                    {landlord?.data?.bank ? landlord?.data?.bank : '--'}
                    {landlord?.data?.accountNumber &&
                      ' | ' + landlord?.data?.accountNumber}
                  </div>
                  <div className="text-center text-xs text-gray">
                    click to copy account number
                  </div>
                </div>
              </div>
            </div>
            <div className="w-7/12 rounded-md p-4 bg-white">
              <div className="flex -items-center justify-between mb-3">
                <span>
                  <span className="text-xl font-bold">Personal Details </span>
                  <p className="text-gray mb-3">Manage User Information</p>
                </span>
                <span className="flex gap-4 items-center">
                  <button
                    onClick={() => handleAction(id, 'flag')}
                    className="text-[#a4803a] border-[2px] rounded-md border-[#CC9933] bg-[#ead3a4] p-3"
                  >
                    {landlord?.data?.isFlagged === true ? 'Unflagged' : 'Flag'}{' '}
                    Landlord
                  </button>
                  <button
                    onClick={() => handleAction(id, 'suspend')}
                    className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
                  >
                    {landlord?.data?.suspend === true ? 'Unsuspend' : 'Suspend'}{' '}
                    Landlord
                  </button>
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-6">
                <span className="mb-3">
                  <label htmlFor="stateoforigin" className="mt-2">
                    State of Origin
                  </label>
                  <input
                    name="stateoforigin"
                    type="text"
                    placeholder="State of Origin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.state ? landlord?.data?.state : '--'}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="nextOfKinName" className="mt-2">
                    Name of Next Kin
                  </label>
                  <input
                    name="nextOfKinName"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      landlord?.data?.nextOfKinName
                        ? landlord?.data?.nextOfKinName
                        : ''
                    }
                    disabled={true}
                  />
                </span>
                <span className="mb-3">
                  <label htmlFor="lga" className="">
                    Local Government
                  </label>
                  <input
                    name="lga"
                    type="text"
                    placeholder="Local Government"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.lga ? landlord?.data?.lga : '--'}
                    disabled={true}
                  />
                </span>
                <span className="mb-3">
                  <label htmlFor="nextOfKinPhone" className="">
                    Phone Number of Next Kin
                  </label>
                  <input
                    name="nextOfKinPhone"
                    type="text"
                    placeholder="Local Government"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      landlord?.data?.nextOfKinPhone
                        ? landlord?.data?.nextOfKinPhone
                        : '--'
                    }
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="occupation" className="mt-2">
                    Occupation
                  </label>
                  <input
                    name="occupation"
                    type="text"
                    placeholder="Phone Number of Next Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      landlord?.data?.occupation
                        ? landlord?.data?.occupation
                        : '--'
                    }
                    disabled={true}
                  />
                </span>
              </div>
              <hr style={{ borderTop: '1px solid #ddd' }} />
              {/* <div className="my-3 grid grid-cols-3 gap-4">
                <span>
                  <label htmlFor="suspend" className="mt-2">
                    Suspended
                  </label>
                  <input
                    name="suspend"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.suspend}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="isFlagged" className="mt-2">
                    Is Flagged
                  </label>
                  <input
                    name="isFlagged"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.isFlagged}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="deleted" className="mt-2">
                    Deleted
                  </label>
                  <input
                    name="deleted"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.deleted}
                    disabled={true}
                  />
                </span>
              </div> */}
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <div className="grid grid-cols-2 gap-4 mt-6">
                <span>
                  <label htmlFor="nextOfKinName" className="mt-2">
                    Name of Next of Kin
                  </label>
                  <input
                    name="nextOfKinName"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      landlord?.data?.nextOfKinName
                        ? landlord?.data?.nextOfKinName
                        : '--'
                    }
                    disabled={true}
                  />
                </span>

                <span>
                  <label htmlFor="nextOfKinPhone" className="">
                    Phone Number of Next Kin
                  </label>
                  <input
                    name="nextOfKinPhone"
                    type="text"
                    placeholder="Phone Number of Next Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      landlord?.data?.nextOfKinPhone
                        ? landlord?.data?.nextOfKinPhone
                        : '--'
                    }
                    disabled={true}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </div>
  );
};

export default LandlordPreview;
