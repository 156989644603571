import React, { useRef, useState } from 'react';
import { useAddPropertyMutation } from '../../hooks/api/Portfolio/useAddPropertyMutation';
import useSelectImage from '../../hooks/useSelectImage';
import toast from 'react-hot-toast';
import { useUploadImageMutation } from '../../hooks/api/Upload/useUploadImageMutation';

type Props = { data: any };

const EditModal = ({ data }: Props) => {
  const [userData, setUserData] = useState({
    title: '',
    description: '',
    address: '',
    principalName: '',
    principalPhone: '',
    principalWhatsapp: '',
    tag: '',
    noOfBedrooms: 0,
    noOfPlot: 0,
    askingPrice: 0,
    images: [],
  });

  const handleTextChange = (e: any) => {
    const { name, value } = e.target;

    const updatedValue =
      name === 'noOfBedrooms' || name === 'noOfPlot' || name === 'askingPrice'
        ? parseInt(value, 10)
        : value;
    setUserData({ ...userData, [name]: updatedValue });
  };
  const { mutateAsync: addProperty } = useAddPropertyMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    addProperty(userData);
  };

  const [file, setFile] = useState('');
  const [imageMessage, setImageMessage] = useState('');

  const inputFile = useRef(null);

  const { handleFileChange, selectedImg, setImageLoading } = useSelectImage();

  const { mutateAsync: uploadImageToApi } = useUploadImageMutation();

  const uploadImageFn = async (selectedImg) => {
    const formData = new FormData();
    formData.append('files', selectedImg);

    Array.from(file).forEach(({ individualfile, index }: any) => {
      formData.append(`file${index + 1}`, individualfile);
    });

    try {
      if (selectedImg) {
        setImageLoading(true);
        const res = uploadImageToApi(formData, {
          onSuccess: (data) => {
            toast.success('testing');
            setUserData({ ...userData, images: data?.data });
          },
        });
        setImageMessage('');
        setImageLoading(false);
        return res;
      }
    } catch (error) {
      console.log('error uploading image', error);
      setImageLoading(false);
    }
  };

  return (
    <div>
      <div className="flex gap-3 h-[80vh] overflow-y-scroll">
        <div className="w-8/12 rounded-md p-4 bg-white">
          <h3 className="text-xl text-black font-bold mb-6">
            Upload a property
          </h3>
          <span className="pb-3 cursor-pointer">
            <div>
              <label htmlFor="images" className="mt-2">
                Uploaded Images
              </label>
              <input
                name="images"
                type="file"
                placeholder="State of Origin"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={handleFileChange}
                multiple={true}
                ref={inputFile}
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </span>
          <button
            className="bg-blue w-full p-2 text-center text-white"
            onClick={() => uploadImageFn(selectedImg)}
          >
            RUN UPLOAD
          </button>
          <div className="my-10 text-black">{imageMessage && imageMessage}</div>
          <span className="mb-3">
            <label htmlFor="title" className="mt-2">
              Title
            </label>
            <input
              name="title"
              type="text"
              placeholder="Title"
              className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
              onChange={(e) => handleTextChange(e)}
              defaultValue={data?.title}
            />
          </span>

          <div className="flex gap-3 py-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="address" className="mt-2">
                Property Address
              </label>
              <input
                name="address"
                type="text"
                placeholder="Property Address"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.address}
              />
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalName" className="mt-2">
                Principal's name
              </label>
              <input
                name="principalName"
                type="text"
                placeholder="John Doe"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.principalName}
              />
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="tag" className="mt-2">
                Property Tag
              </label>
              <div>
                <select
                  name="tag"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                  defaultValue={data?.tag}
                >
                  <option value="" className="w-full">
                    Select option
                  </option>
                  <option value="sale" className="w-full">
                    For Sale
                  </option>
                  <option value="lease" className="w-full">
                    For Lease
                  </option>
                </select>
              </div>
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalPhone" className="mt-2">
                Principal's Phone
              </label>
              <input
                name="principalPhone"
                type="text"
                placeholder="1234567890"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.principalPhone}
              />
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="askingPrice" className="mt-2">
                Asking Price (&#8358;)
              </label>
              <input
                name="askingPrice"
                type="number"
                placeholder=""
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.askingPrice}
              />
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalWhatsapp" className="mt-2">
                Principal's WhatsApp
              </label>
              <input
                name="principalWhatsapp"
                type="text"
                placeholder="1234567890"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.principalWhatsapp}
              />
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <div className="w-6/12">
              <div className="mb-3">
                <label htmlFor="noOfPlot" className="mt-2">
                  No. of Plots
                </label>
                <input
                  name="noOfPlot"
                  type="number"
                  placeholder=""
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                  defaultValue={data?.noOfPlot}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="noOfBedrooms" className="mt-2">
                  No. of Bedrooms
                </label>
                <input
                  name="noOfBedrooms"
                  type="number"
                  placeholder=""
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                  defaultValue={data?.noOfBedrooms}
                />
              </div>
            </div>
            <div className="w-6/12">
              <label htmlFor="description" className="mt-2">
                Description
              </label>

              <textarea
                name="description"
                cols={30}
                rows={5}
                className="text-black bg-slate-100 p-4 resize-none rounded-md w-full my-3"
                onChange={(e) => handleTextChange(e)}
                defaultValue={data?.description}
              />
            </div>
          </div>
        </div>
        <div className="w-4/12 rounded-md p-4">
          <button
            className="text-xs text-white bg-blue px-3 py-3 rounded-md mt-10"
            onClick={(e) => handleSubmit(e)}
          >
            Publish Property
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
