import axios from 'axios';
import toast from 'react-hot-toast';

export const axiosInstance = axios.create({
  baseURL: 'https://staging-api.rentaly.rent/api/v1/',
});

const stringifyAccessToken = localStorage.getItem('@accessToken');
const token = JSON.parse(stringifyAccessToken);

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error) => {
    // Do something with request error
    toast.error(error);
    return Promise.reject(error);
  }
);
