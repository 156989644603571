import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import './index.css';
import Overview from './pages/Overview';
import Listings from './pages/Listings';
import Applications from './pages/Applications';
import Landlords from './pages/Landlords';
import TenantPreview from './pages/Tenants/TenantPreview';
import Tenants from './pages/Tenants';
import PropertyVerification from './pages/PropertyVerification';
import Transactions from './pages/Transactions';
import DashboardLayout from './components/DashboardLayout';
import LandlordPreview from './pages/Landlords/LandlordPreview';
import Refunds from './pages/Refunds';
import Portfolio from './pages/Portfolio';

import { queryClient } from './services/queryClient';
import { AppProvider } from './contexts/AppContext';
import Staff from './pages/Staff';
import PortfolioPeview from './pages/Portfolio/PortfolioPeview';
import ListingPreview from './pages/Listings/ListingPreview';
import PortfolioUpload from './pages/Portfolio/PortfolioUpload';
import SignIn from './pages/SignIn';
import StaffPreview from './pages/Staff/StaffPreview';
import Error from './pages/Error';
import TransactionsPreview from './pages/Transactions/TransactionsPreview';
import PropertyVerificationPreview from './pages/PropertyVerification/PropertyVerificationPreview';
import Profile from './pages/Profile';
import ApplicationPreview from './pages/Applications/ApplicationPreview';
import RefundsPreview from './pages/Refunds/RefundsPreview';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// let user = localStorage.getItem('@user');

const superAdminRouter = createBrowserRouter([
  { path: '/', element: <SignIn /> },
  { path: '/overview', element: <Overview /> },
  { path: '/landlords', element: <Landlords /> },
  {
    path: '/landlords/:id',
    element: <LandlordPreview />,
  },
  { path: '/profile', element: <Profile /> },
  { path: '/tenants', element: <Tenants /> },
  { path: '/tenants/:id', element: <TenantPreview /> },
  { path: '/listings', element: <Listings /> },
  { path: '/listings/:id', element: <ListingPreview /> },
  { path: '/portfolio', element: <Portfolio /> },
  { path: '/portfolio/:id', element: <PortfolioPeview /> },
  { path: '/portfolio/upload', element: <PortfolioUpload /> },
  { path: '/applications', element: <Applications /> },
  { path: '/applications/:id', element: <ApplicationPreview /> },
  { path: '/verifications', element: <PropertyVerification /> },
  {
    path: '/verifications/:id',
    element: <PropertyVerificationPreview />,
  },
  { path: '/transactions', element: <Transactions /> },
  { path: '/transactions/:id', element: <TransactionsPreview /> },
  { path: '/staff', element: <Staff /> },
  { path: '/staff/:id', element: <StaffPreview /> },
  { path: '/refunds', element: <Refunds /> },
  { path: '/refunds/:id', element: <RefundsPreview /> },
  {
    path: '/portfolio',
    element: <DashboardLayout>PORTFOLIO</DashboardLayout>,
  },
  { path: '*', element: <Error /> },
]);

root.render(
  <AppProvider>
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
          },
        }}
      />
      <RouterProvider router={superAdminRouter} />
    </QueryClientProvider>
  </AppProvider>
);
