import { useMutation } from 'react-query';

import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export const useChangeAdminPasswordMutation = (oldPassword, password) => {
  const navigate = useNavigate();

  const config = {
    oldPassword: oldPassword,
    password: password,
  };

  return useMutation(
    () =>
      axiosInstance
        .patch(`${apiConfig.ADMIN}/change-password`, config)
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success('Password changed successfully');
        setTimeout(() => {
          localStorage.clear();
          navigate('/');
        }, 500);
      },
      onError: (error: any) => {
        toast.error('Changed change failed');
      },
    }
  );
};
