import React from 'react';

type Props = {
  familiesAllowed: boolean;
  workingClass: boolean;
  studentAllowed: boolean;
  petsAllowed: boolean;
  smokersAllowed: boolean;
};

const activeClass = 'text-white bg-blue rounded-full bg-blue';
const inActiveClass =
  'bg-white text-blue border-[1px] border-blue rounded-full';

const PropertyPreference = ({
  familiesAllowed,
  workingClass,
  studentAllowed,
  petsAllowed,
  smokersAllowed,
}: Props) => {
  const data = {
    familiesAllowed: familiesAllowed,
    workingClass: workingClass,
    studentAllowed: studentAllowed,
    petsAllowed: petsAllowed,
    smokersAllowed: smokersAllowed,
  };
  return (
    <div className="grid gap-3 grid-cols-3 cursor-none">
      {Object.entries(data).map(([key, value]) => (
        <span
          key={key}
          className={`text-black text-center p-2 text-xs ${
            value ? activeClass : inActiveClass
          }`}
        >
          {key === 'familiesAllowed' && 'Family'}
          {key === 'workingClass' && 'Working Class'}
          {key === 'studentAllowed' && 'Student'}
          {key === 'petsAllowed' && 'Pets'}
          {key === 'smokersAllowed' && 'Smokers'}
        </span>
      ))}
    </div>
  );
};

export default PropertyPreference;
