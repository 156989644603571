import { Spin } from 'antd';

type Prop = {
  text?: string;
};

const LoadingComponent = ({ text }: Prop) => {
  return (
    <div className={`${text && 'py-[30vh]'}`}>
      <Spin className={`mx-auto block h-full ${text ? '' : 'py-[30vh]'}`} />
      <div className="block text-center capitalize mt-4">{text}</div>
    </div>
  );
};

export default LoadingComponent;
