import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

interface Image {
  name: string;
  imageUrl: string;
}

interface ImageGalleryProps {
  images: Image[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  if (images.length === 0) {
    return (
      <div
        className="text-gray"
        style={{ backgroundColor: 'grey', height: '400px' }}
      >
        No images available
      </div>
    );
  } else if (images.length === 1) {
    const imageUrl = images[0].imageUrl;

    return (
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '400px',
        }}
      />
    );
  } else {
    return (
      <div>
        <Slide indicators={true} autoplay={true}>
          {images.map((image, index) => (
            <div key={index} className="w-full">
              <div
                style={{
                  backgroundImage: `url(${image.imageUrl})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '500px',
                }}
              ></div>
            </div>
          ))}
        </Slide>
      </div>
    );
  }
};

export default ImageGallery;
