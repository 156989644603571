import { useQuery } from 'react-query';
import { axiosInstance } from '../../utils/axiosInstance';

export const useFetchPaginatedData = (
  apiUrl,
  queryName,
  page = 1,
  limit = 15,
  query = ''
) => {
  const config = {
    params: { page: page, limit: limit, search: query },
  };

  return useQuery({
    queryKey: [queryName, page, limit, query],
    queryFn: () => axiosInstance.get(apiUrl, config).then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ ${queryName}`);
    },
    onError: (error) => {
      console.log(`❌ ${queryName}`);
    },
  });
};
