import React from 'react';
import { Link, useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import BackButton from '../../components/BackButton';
import { useGetOneRefundQuery } from '../../hooks/api/Refund/useGetOneRefundQuery';
import { Spin } from 'antd';
import { MdOutlineEmail, MdPhone } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { Slide } from 'react-slideshow-image';
import { IoLocationOutline } from 'react-icons/io5';

type Props = {};

const RefundsPreview = (props: Props) => {
  const { id } = useParams();
  const { data: refund, isLoading, isFetching } = useGetOneRefundQuery(id);
  const Avatar = require('../../assets/avatar.jpg');

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>

      {isLoading ||
        (isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />)}

      <div className=" h-[75vh] overflow-y-scroll p-[20px] rounded-md text-base">
        {refund && (
          <div className="flex gap-4">
            <div className="w-8/12 bg-white rounded-md p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold">Property Details</h3>
                <div>
                  {refund?.data?.application?.listing?.annualRent ? (
                    <NumericFormat
                      value={refund?.data?.application?.listing?.annualRent}
                      prefix="&#8358;"
                      thousandSeparator=","
                      className="text-gray"
                      displayType="text"
                    />
                  ) : (
                    '--'
                  )}
                  /year
                </div>
              </div>
              <div className="my-5">
                {refund?.data?.application?.listing?.images.length === 1 &&
                refund?.data?.application?.listing?.images[0] === '' ? (
                  <div
                    className=""
                    style={{ height: '400px', backgroundColor: '#ddd' }}
                  ></div>
                ) : (
                  <div>
                    <Slide>
                      {refund?.data?.application?.listing?.images.map(
                        (slideImage, index) => (
                          <div key={index}>
                            <div
                              style={{
                                backgroundImage: `url(${slideImage})`,
                                backgroundSize: 'cover',
                                height: '400px',
                              }}
                            ></div>
                          </div>
                        )
                      )}
                    </Slide>
                  </div>
                )}
              </div>
              <h3 className="font-bold capitalize">
                {refund?.data?.application?.listing?.propertyType
                  ? refund?.data?.application?.listing?.propertyType
                  : '--'}
              </h3>
              <div className="flex gap-3 mt-3">
                <IoLocationOutline className=" " />
                <span className="text-gray ">
                  {refund?.data?.application?.listing?.addressLine1 &&
                    refund?.data?.application?.listing?.addressLine1 + ', '}
                  {refund?.data?.application?.listing?.addressLine2 !== '' &&
                    refund?.data?.application?.listing?.addressLine2 + ', '}
                  {refund?.data?.application?.listing?.city !== '' &&
                    refund?.data?.application?.listing?.city + ', '}
                  {refund?.data?.application?.listing?.state !== '' &&
                    refund?.data?.application?.listing?.state}
                </span>
              </div>

              <h3 className="font-bold  mt-3">Description</h3>
              <p className=" text-gray">
                {refund?.data?.application?.listing?.description
                  ? refund?.data?.application?.listing?.description
                  : '--'}
              </p>
            </div>

            <div className="w-4/12">
              <div className="bg-white rounded-md p-4 mb-4">
                <h3 className="text-lg font-bold mb-3">Landlord</h3>
                <div className="flex gap-3">
                  <img
                    src={
                      refund?.data?.application?.landlord?.image === ''
                        ? Avatar
                        : refund?.data?.application?.landlord?.image
                    }
                    alt=""
                    className="h-[60px] w-[60px] rounded-full text-xs bg-ash"
                  />
                  <span>
                    <h3 className="font-bold  text-black">
                      {refund?.data?.application?.landlord?.firstName
                        ? refund?.data?.application?.landlord?.firstName
                        : '--'}{' '}
                      {refund?.data?.application?.landlord?.lastName
                        ? refund?.data?.application?.landlord?.lastName
                        : '--'}
                    </h3>
                  </span>
                </div>
                <div className="flex gap-3 items-center my-3">
                  <MdPhone />
                  <span>
                    {refund?.data?.application?.landlord?.phone
                      ? refund?.data?.application?.landlord?.phone
                      : '--'}
                  </span>
                </div>
                <div className="flex gap-3 items-center mb-3">
                  <MdOutlineEmail />
                  <span>
                    {refund?.data?.application?.landlord?.email
                      ? refund?.data?.application?.landlord?.email
                      : '--'}
                  </span>
                </div>
                <Link
                  to={`/landlords/${refund?.data?.application?.landlord?.id}`}
                  className="text-sm text-white px-3 py-3 bg-blue rounded-md inline-block shadow-md"
                >
                  View Profile
                </Link>
              </div>
              <div className="bg-white rounded-md p-4 mb-4">
                <h3 className="text-lg font-bold mb-3">Tenant</h3>
                <div className="flex gap-3">
                  <img
                    src={
                      refund?.data?.application?.tenant?.image === ''
                        ? Avatar
                        : refund?.data?.application?.tenant?.image
                    }
                    alt=""
                    className="h-[60px] w-[60px] rounded-full text-xs bg-ash"
                  />
                  <span>
                    <h3 className="font-bold  text-black">
                      {refund?.data?.application?.tenant?.firstName
                        ? refund?.data?.application?.tenant?.firstName
                        : '--'}{' '}
                      {refund?.data?.application?.tenant?.lastName
                        ? refund?.data?.application?.tenant?.lastName
                        : '--'}
                    </h3>
                  </span>
                </div>
                <div className="flex gap-3 items-center my-3">
                  <MdPhone />
                  <span>
                    {refund?.data?.application?.tenant?.phone
                      ? refund?.data?.application?.tenant?.phone
                      : '--'}
                  </span>
                </div>
                <div className="flex gap-3 items-center mb-3">
                  <MdOutlineEmail />
                  <span>
                    {refund?.data?.application?.tenant?.email
                      ? refund?.data?.application?.tenant?.email
                      : '--'}
                  </span>
                </div>
                <Link
                  to={`/tenants/${refund?.data?.application?.tenant?.id}`}
                  className=" text-white text-sm  px-3 py-3 bg-blue rounded-md inline-block shadow-md"
                >
                  View Profile
                </Link>
              </div>
              <div className="bg-white rounded-md p-4 mb-4">
                <h3 className="text-lg font-bold mb-3">Reason for refund</h3>
                <p className="">
                  {refund?.data?.reason !== '' ? (
                    refund?.data?.reason
                  ) : (
                    <span className="text-gray">No reason</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default RefundsPreview;
