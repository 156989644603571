import { useQuery } from 'react-query';
import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';

export const useGetAdminActivitiesQuery = (id, page) => {
  const config = {
    params: { page: page },
  };

  return useQuery({
    queryKey: 'getAdminActivities',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.ADMIN}/${id}/activities`, config)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log(`✅ getAdminActivities`);
    },
    onError: (error) => {
      console.log(`❌ getAdminActivities`);
    },
    enabled: id === null ? false : true,
  });
};
