import React from 'react';
import { AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsHouses } from 'react-icons/bs';
import { Spin } from 'antd';

type Props = { data: any; loading: any };

const OverallCount = ({ data, loading }: Props) => {
  return (
    <div className="bg-white p-3 rounded-md shadow-sm md:flex md:flex-row flex-col gap-5">
      <div className="md:w-4/12 flex items-center gap-3 md:border-r-[1px] border-r-0 border-blue ">
        <span className="bg-blue rounded-full p-3">
          <AiOutlineUser className="text-white text-2xl" />
        </span>
        <span>
          <h3 className="font-bold text-black1 text-lg">Landlords</h3>
          <div className="text-gray">
            {loading && <Spin size="small" />}

            {JSON.stringify(data?.data?.landlords)}
          </div>
        </span>
      </div>
      <div className="md:w-4/12 flex items-center gap-3 md:border-r-[1px] border-r-0 border-blue ">
        <span className="bg-blue rounded-full p-3">
          <AiOutlineUsergroupAdd className="text-white text-2xl" />
        </span>
        <span>
          <h3 className="font-bold text-black1 text-lg">Tenants</h3>
          <div className="text-gray">
            {loading && <Spin size="small" />}{' '}
            {JSON.stringify(data?.data?.tenants)}
          </div>
        </span>
      </div>
      <div className="md:w-4/12 flex items-center gap-3">
        <span className="bg-blue rounded-full p-3">
          <BsHouses className="text-white text-2xl" />
        </span>
        <span>
          <h3 className="font-bold text-black1 text-lg">
            Total No. of listed Properties
          </h3>
          {loading && <Spin size="small" />}
          {JSON.stringify(data?.data?.listings)}
        </span>
      </div>
    </div>
  );
};

export default OverallCount;
