export const apiConfig = {
  SIGNIN: 'admins/login',
  ME: 'admins/me',
  LANDLORDS: 'landlords',
  TENANTS: '/tenants',
  SUSPEND: '/admins/suspend',
  UNSUSPEND: '/admins/un-suspend',
  LISTINGS: '/listings',
  ADMIN: '/admins',
  PROPERTIES: '/properties',
  TRANSACTIONS: '/wallets/admins/transactions',
  DELETE: '/admins/delete',
  DASHBOARD: '/admins/dashboard',
  APPLICATION: '/applications/admin-applications',
  REFUND: '/wallets/refunds',
  UPLOAD: '/misc/file-uploads',
};
