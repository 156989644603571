import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';

export const useUploadImageMutation = () => {
  return useMutation(
    (file: any) =>
      axiosInstance.post(`${apiConfig.UPLOAD}`, file).then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success(data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        console.log('❌ UPLOAD IMAGE(S)');
      },
    }
  );
};
