import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import ListView from './ListView';
import { useGetAllPropertyListingQuery } from '../../hooks/api/Listing/useGetAllPropertyListingQuery';
import { Link } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import LoadingComponent from '../../components/LoadingComponent';

const Listings = () => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: propertyListing, isFetching } = useGetAllPropertyListingQuery(
    currentPage,
    15,
    searchParam
  );

  const pages = Array.from(
    { length: propertyListing?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold">Listings </span>

            {propertyListing && (
              <span className="bg-[#4D4D94] text-white text-xs p-1 rounded-md">
                {propertyListing?.data?.total}
              </span>
            )}
          </div>
          <p className="text-gray mt-2">View Listings</p>
        </span>
        <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <LoadingComponent />}
      {propertyListing?.data?.total === 0 && (
        <p className="text-center text-gray ">
          No data available for this search
        </p>
      )}
      <div className="grid grid-cols-3 gap-4 h-[72vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {!isFetching &&
          propertyListing &&
          propertyListing.data.data.map(
            ({
              id,
              maxNoOfTenants,
              status,
              approvedBy,
              isRented,
              address,
              title,
              annualRent,
              noOfBathrooms,
              noOfBedrooms,
              noOfLivingRoom,
              landlord,
              images,
            }) => (
              <Link to={`/listings/${id}`} key={id}>
                <ListView
                  maxNoOfTenants={maxNoOfTenants}
                  title={title}
                  address={address}
                  status={status}
                  approvedBy={approvedBy}
                  isRented={isRented}
                  annualRent={annualRent}
                  noOfBathrooms={noOfBathrooms}
                  noOfBedrooms={noOfBedrooms}
                  noOfLivingRoom={noOfLivingRoom}
                  landlord={landlord}
                  images={images}
                />
              </Link>
            )
          )}
      </div>
      {propertyListing?.data?.last_page > 1 && (
        <div className="h-[7vh] flex justify-between items-center">
          <span className="text-gray">
            Showing page {currentPage} of {propertyListing?.data?.last_page}
          </span>
          <span className="flex gap-3 items-center">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={
                propertyListing?.data?.current_page === 1 ? true : false
              }
              className={` ${
                propertyListing?.data?.current_page === 1
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <FaChevronCircleLeft />
            </button>
            {pages.map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
                className={`border-[1px] rounded-md p-2 border-black text-xs ${
                  currentPage === page
                    ? ' cursor-not-allowed bg-blue text-white'
                    : ''
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === propertyListing?.data?.last_page}
              className={`${
                currentPage === propertyListing?.data?.last_page
                  ? 'opacity-50'
                  : ''
              }`}
            >
              <FaChevronCircleRight />
            </button>
          </span>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Listings;
