import React, { useState } from 'react';
import { BsFlag } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import { useGetOneAdminQuery } from '../../hooks/api/Admin/useGetOneAdminQuery';
import { RiUserForbidLine } from 'react-icons/ri';
import { Modal, Spin } from 'antd';
import RolesModal from './Modals/RolesModal';
import DeleteAdmin from './DeleteAdmin';
import { useGetAdminActivitiesQuery } from '../../hooks/api/Admin/useGetAdminActivitiesQuery';
import Moment from 'react-moment';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import BackButton from '../../components/BackButton';

const StaffPreview = () => {
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: admin, isLoading, isFetching } = useGetOneAdminQuery(id);
  const { data: staffActivities } = useGetAdminActivitiesQuery(id, currentPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleAction = (title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const pages = Array.from(
    { length: staffActivities?.data?.last_page },
    (_, index) => index + 1
  );

  const Avatar = require('../../assets/avatar.jpg');

  return (
    <div>
      <DashboardLayout>
        <Header>
          <BackButton />
        </Header>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={400}
        >
          {content === 'role' && (
            <RolesModal payload={admin} handleCancel={handleCancel} />
          )}
          {content === 'delete' && (
            <DeleteAdmin id={admin?.data?.id} handleCancel={handleCancel} />
          )}
        </Modal>
        {isLoading ||
          (isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />)}

        <div className="flex justify-between">
          <span />
          <span className="flex gap-4 items-center">
            <button
              onClick={() => handleAction('role')}
              className="text-white border-[2px] rounded-md border-blue bg-blue p-3"
            >
              Change Role
            </button>
            <button
              onClick={() => handleAction('delete')}
              className="text-[#FF0000] border-[#FF0000] bg-[#ffa1a1] border-[2px] rounded-md  p-3"
            >
              Delete Admin
            </button>
          </span>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-8/12 rounded-md bg-white ">
            <h3 className="text-xl font-bold mb-3 px-4 pt-4">Activities</h3>
            <div className="rounded-md p-4 h-[60vh] overflow-y-scroll">
              <div className="">
                {staffActivities?.data?.data.length === 0 && (
                  <p className="mt-[15vh] text-center text-gray">
                    NO DATA AVAILABLE
                  </p>
                )}
                {staffActivities?.data?.data.length > 0 && (
                  <div>
                    {staffActivities?.data?.data.map((payload) => (
                      <div className="mb-4 p-4 shadow-md rounded-sm bg-slate-100">
                        <div className="text-black">
                          <span className="font-bold mr-2">Action:</span>
                          <span className="text-gray">{payload?.action}</span>
                        </div>
                        <div className="text-black">
                          <span className="font-bold mr-2">Description:</span>
                          <span className="text-gray">
                            {payload?.description}
                          </span>
                        </div>
                        <div className="text-black">
                          <span className="font-bold mr-2">IP Address:</span>
                          <span className="text-gray">{payload?.ip}</span>
                        </div>
                        <div className="text-black">
                          <span className="font-bold mr-2">Device:</span>
                          <span className="text-gray">{payload?.device}</span>
                        </div>
                        <div className="text-black">
                          <span className="font-bold mr-2">Timing:</span>
                          <span className="text-gray">
                            <Moment format="YYYY/MM/DD HH:MM:SS" interval={0}>
                              {payload?.createdAt}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="h-[7vh] flex justify-between items-center">
              <span className="text-gray">
                Showing page {currentPage} of {staffActivities?.data?.last_page}
              </span>
              <span className="flex gap-3 items-center">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={
                    staffActivities?.data?.current_page === 1 ? true : false
                  }
                  className={` ${
                    staffActivities?.data?.current_page === 1
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  <FaChevronCircleLeft />
                </button>
                {pages.map((page) => (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    disabled={currentPage === page}
                    className={`border-[1px] rounded-md p-2 border-black text-xs ${
                      currentPage === page
                        ? ' cursor-not-allowed bg-blue text-white'
                        : ''
                    }`}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={null === staffActivities?.data?.next_page}
                  className={`${
                    null === staffActivities?.data?.next_page
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  <FaChevronCircleRight />
                </button>
              </span>
            </div>
          </div>
          <div className="w-4/12 rounded-md">
            <div className="rounded-md p-4 bg-white">
              <h3 className="text-xl font-bold mb-3">Admin Details</h3>
              <div className="mt-3 flex gap-3 items-center">
                <img
                  src={admin?.data?.image ? admin?.data?.image : Avatar}
                  alt="avatar"
                  className="h-[80px] w-[80px] rounded-full "
                />
                <div>
                  <h3 className="text-md text-black font-bold">
                    {admin?.data?.firstName
                      ? admin?.data?.firstName + ' ' + admin?.data?.lastName
                      : '--'}
                  </h3>
                  <p className="text-gray text-sm">
                    {admin?.data?.roles ? admin?.data?.roles : '--'}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 my-3 items-center">
                <MdOutlineEmail className="text-xl" />
                <span className="text-gray text-md">
                  {admin?.data?.email ? admin?.data?.email : '--'}
                </span>
              </div>

              <div className="flex gap-3 my-3 items-center">
                <RiUserForbidLine className="text-xl" />
                <span className="text-gray text-md">
                  {admin?.data?.suspendedLandlord
                    ? admin?.data?.suspendedLandlord +
                      admin?.data?.suspendedTenant
                    : 0}{' '}
                  Suspended Account
                  {`${
                    admin?.data?.suspendedLandlord +
                      admin?.data?.suspendedTenant >
                    1
                      ? 's'
                      : ''
                  }`}
                </span>
              </div>

              <div className="flex gap-3 items-center">
                <BsFlag className="text-xl" />
                <span className="text-gray text-md">
                  {admin?.data?.flaggedTenant
                    ? admin?.data?.flaggedTenant + admin?.data?.flaggedLandlord
                    : 0}{' '}
                  Flagged Account
                  {`${
                    admin?.data?.flaggedTenant + admin?.data?.flaggedLandlord >
                    1
                      ? 's'
                      : ''
                  }`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default StaffPreview;
