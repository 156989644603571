import { apiConfig } from '../../../config/apiConfig';
import { useFetchPaginatedData } from '../useFetchPaginatedData';

export const useGetPropertiesQuery = (page = 1, limit = 15, query) => {
  return useFetchPaginatedData(
    apiConfig.PROPERTIES,
    'getLandlords',
    page,
    limit,
    query
  );
};
