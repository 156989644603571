import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetRefundsQuery = () => {
  return useQuery({
    queryKey: 'useGetRefundsQuery',
    queryFn: () =>
      axiosInstance.get(`${apiConfig.REFUND}`).then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET REFUNDS');
    },
    onError: (error: any) => {
      console.log('❌ GET REFUNDS ==>', error.message);
    },
  });
};
