import React, { useState } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { MdVerified } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';
import { Modal, Spin, Tooltip } from 'antd';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import SuspendLandlord from './SuspendTenant';
import FlagLandlord from './FlagTenant';
import { useGetOneTenantQuery } from '../../hooks/api/Tenants/useGetOneTenantQuery';
import BackButton from '../../components/BackButton';

const TenantPreview = () => {
  const { id } = useParams();
  const {
    data: tenant,
    isLoading: isLoadingTenant,
    isFetching,
  } = useGetOneTenantQuery(id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleAction = (id, title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const Avatar = require('../../assets/avatar.jpg');

  return (
    <div>
      <DashboardLayout>
        <Header>
          <BackButton />
        </Header>

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width={content === 'more' ? 1000 : 400}
        >
          {content === 'suspend' && (
            <SuspendLandlord
              id={id}
              status={tenant?.data?.suspend}
              handleCancel={handleCancel}
            />
          )}
          {content === 'flag' && (
            <FlagLandlord
              id={id}
              status={tenant?.data?.isFlagged}
              handleCancel={handleCancel}
            />
          )}
        </Modal>

        {isLoadingTenant ||
          (isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />)}
        {tenant && (
          <div className="flex gap-4">
            <div className="w-5/12 rounded-md p-4 bg-white">
              <img
                src={tenant?.data?.image !== '' ? tenant?.data?.image : Avatar}
                alt="avatar"
                className="h-[80px] w-[80px] rounded-full mx-auto"
              />
              <div className="text-center text-2xl font-bold flex gap-2 items-center justify-center">
                {tenant?.data?.firstName ? tenant?.data?.firstName : '--'}{' '}
                {tenant?.data?.lastName ? tenant?.data?.lastName : '--'}
                {tenant?.data?.isProfileComplete && (
                  <Tooltip title="Email is verified">
                    <MdVerified className="text-sky-500 cursor-pointer" />
                  </Tooltip>
                )}
              </div>
              <p>Bio</p>
              <textarea
                name=""
                id=""
                cols={30}
                rows={3}
                className="text-black bg-ash p-4 resize-none rounded-md w-full mb-3"
                value={
                  tenant?.data?.moreDescription
                    ? tenant?.data?.moreDescription
                    : '--'
                }
                disabled={true}
              />
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <div className="flex gap-3 mb-5 mt-3">
                <FiMail className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Email Address</h4>
                  <span className="text-stone-500">
                    {tenant?.data?.email ? tenant?.data?.email : '--'}
                  </span>
                </span>
              </div>
              <div className="flex gap-3 mb-5 mt-3">
                <BsTelephone className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Phone Number</h4>
                  <span className="text-stone-500">
                    {tenant?.data?.phone ? tenant?.data?.phone : '--'}
                  </span>
                </span>
              </div>
            </div>
            <div className="w-7/12 rounded-md p-4 bg-white">
              <div className="flex -items-center justify-between mb-3">
                <span>
                  <span className="text-xl font-bold">Personal Details </span>
                  <p className="text-gray mb-3">Manage User Information</p>
                </span>
                <span className="flex gap-4 items-center">
                  <button
                    onClick={() => handleAction(id, 'flag')}
                    className="text-[#a4803a] border-[2px] rounded-md border-[#CC9933] bg-[#ead3a4] p-3"
                  >
                    {tenant?.data?.isFlagged === true ? 'Unflagged' : 'Flag'}{' '}
                    Tenant
                  </button>
                  <button
                    onClick={() => handleAction(id, 'suspend')}
                    className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
                  >
                    {tenant?.data?.suspend === true ? 'Unsuspend' : 'Suspend'}{' '}
                    Tenant
                  </button>
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-6">
                <span className="mb-3">
                  <label htmlFor="stateoforigin" className="mt-2">
                    State of Origin
                  </label>
                  <input
                    name="stateoforigin"
                    type="text"
                    placeholder="State of Origin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={tenant?.data?.state ? tenant?.data?.state : '--'}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="nextOfKinName" className="mt-2">
                    Name of Next Kin
                  </label>
                  <input
                    name="nextOfKinName"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      tenant?.data?.nextOfKinName
                        ? tenant?.data?.nextOfKinName
                        : ''
                    }
                    disabled={true}
                  />
                </span>
                <span className="mb-3">
                  <label htmlFor="lga" className="">
                    Local Government
                  </label>
                  <input
                    name="lga"
                    type="text"
                    placeholder="Local Government"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={tenant?.data?.lga ? tenant?.data?.lga : '--'}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="occupation" className="mt-2">
                    Occupation
                  </label>
                  <input
                    name="occupation"
                    type="text"
                    placeholder="Phone Number of Next Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      tenant?.data?.occupation ? tenant?.data?.occupation : '--'
                    }
                    disabled={true}
                  />
                </span>
              </div>
              <hr style={{ borderTop: '1px solid #ddd' }} />
              {/* <div className="my-3 grid grid-cols-3 gap-4">
                <span>
                  <label htmlFor="suspend" className="mt-2">
                    Suspended
                  </label>
                  <input
                    name="suspend"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.suspend}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="isFlagged" className="mt-2">
                    Is Flagged
                  </label>
                  <input
                    name="isFlagged"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.isFlagged}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="deleted" className="mt-2">
                    Deleted
                  </label>
                  <input
                    name="deleted"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.deleted}
                    disabled={true}
                  />
                </span>
              </div> */}
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <div className="grid grid-cols-2 gap-4 mt-6">
                <span>
                  <label htmlFor="nextOfKinName" className="mt-2">
                    Name of Next of Kin
                  </label>
                  <input
                    name="nextOfKinName"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      tenant?.data?.nextOfKinName
                        ? tenant?.data?.nextOfKinName
                        : '--'
                    }
                    disabled={true}
                  />
                </span>

                <span>
                  <label htmlFor="nextOfKinPhone" className="">
                    Phone Number of Next Kin
                  </label>
                  <input
                    name="nextOfKinPhone"
                    type="text"
                    placeholder="Phone Number of Next Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      tenant?.data?.nextOfKinPhone
                        ? tenant?.data?.nextOfKinPhone
                        : '--'
                    }
                    disabled={true}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </div>
  );
};

export default TenantPreview;
