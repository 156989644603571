import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

export const useAddPropertyMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: any) =>
      axiosInstance
        .post(`${apiConfig.PROPERTIES}`, payload)
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success('Property uploaded successfully');
        console.log('✅ ADD PROPERTY');
        setTimeout(() => {
          navigate(-1);
        }, 500);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data);
        console.log('❌ ADD TENANT');
      },
    }
  );
};
