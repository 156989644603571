import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import { useGetOneListingQuery } from '../../hooks/api/Listing/useGetOneListingQuery';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineEmail, MdPhone } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import PropertyChecklist from './PropertyChecklist';
import PropertyPreference from './PropertyPreference';
import BackButton from '../../components/BackButton';
import ApproveModal from './Modals/ApproveModal';
import ImageGallery from '../../components/ImageGallery';
import DeclineModal from './Modals/DeclineModal';

const PropertyVerificationPreview = () => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const { data: listing, isLoading, isFetching } = useGetOneListingQuery(id);
  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const handleAction = (title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const Avatar = require('../../assets/avatar.jpg');

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      <div className="flex justify-between">
        <span></span>
        <span className="">
          {listing && (
            <div className="flex items-center justify-between mb-3">
              <span className="flex gap-4 items-center">
                {/* {listing?.data?.status === 'pending' && ( */}
                <button
                  onClick={() => handleAction('approve')}
                  className="text-white border-[2px] rounded-md border-blue bg-blue p-3"
                >
                  Approve
                </button>
                {/* )} */}
                <button
                  onClick={() => handleAction('decline')}
                  className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
                >
                  Decline
                </button>
              </span>
            </div>
          )}
        </span>
      </div>
      {isLoading && <Spin className="mx-auto block h-full mt-[30vh]" />}
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={content === 'approve' || content === 'decline' ? 400 : 800}
      >
        {content === 'approve' && (
          <ApproveModal id={id} handleCancel={handleCancel} />
        )}
        {content === 'decline' && (
          <DeclineModal id={id} handleCancel={handleCancel} />
        )}
        {content === 'verification' && (
          <div>
            <h3 className="font-bold">VERIFICATION METHOD</h3>
            <div>
              {listing?.data?.verificationMethod &&
                listing?.data?.verificationMethod.map((item, index) => (
                  <img
                    key={index}
                    className="text-black w-full my-10"
                    src={item}
                    alt="preview"
                    loading="lazy"
                  />
                ))}
            </div>
          </div>
        )}
        {content === 'proof' && (
          <div>
            <h3 className="font-bold">PROOF OF OWNERSHIP</h3>
            <div>
              {listing?.data?.proofOfOwnership &&
                listing?.data?.proofOfOwnership.map((item, index) => (
                  <img
                    key={index}
                    className="text-black w-full my-10"
                    src={item}
                    alt="preview"
                    loading="lazy"
                  />
                ))}
            </div>
          </div>
        )}
      </Modal>
      <div className=" h-[75vh] overflow-y-scroll p-[20px] rounded-md">
        {listing && (
          <div className="flex gap-4">
            <div className="w-8/12 bg-white rounded-md p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold">Property Details</h3>
                <span className="text-gray">
                  {listing?.data?.annualRent ? (
                    <NumericFormat
                      value={listing?.data?.annualRent}
                      prefix="&#8358;"
                      thousandSeparator=","
                      displayType="text"
                    />
                  ) : (
                    '--'
                  )}
                  /year
                </span>
              </div>
              <div className="my-5">
                <ImageGallery images={listing?.data?.images} />
              </div>
              <h3 className="font-bold text-lg capitalize">
                {listing?.data?.propertyType
                  ? listing?.data?.propertyType
                  : '--'}
              </h3>
              <div className="flex gap-3 mt-3">
                <IoLocationOutline className=" text-xl" />
                <span className="text-gray">
                  {listing?.data?.addressLine1 &&
                    listing?.data?.addressLine1 + ', '}
                  {listing?.data?.addressLine2 !== '' &&
                    listing?.data?.addressLine2 + ', '}
                  {listing?.data?.city !== '' && listing?.data?.city + ', '}
                  {listing?.data?.state !== '' && listing?.data?.state}
                </span>
              </div>
              <h3 className="font-bold text-2xl mt-3">Description</h3>
              <p>
                {listing?.data?.description ? listing?.data?.description : '--'}
              </p>
            </div>

            <div className="w-4/12">
              <div className="bg-white rounded-md p-4">
                <h3 className="text-xl font-bold mb-3">Listed by</h3>
                <div className="flex gap-3">
                  <img
                    src={
                      listing?.data?.landlord?.image !== ''
                        ? listing?.data?.landlord?.image
                        : Avatar
                    }
                    alt="avatar"
                    className="h-[60px] w-[60px] rounded-full bg-[#cbcbcb]"
                  />
                  <span>
                    <h3 className="font-bold text-xl text-black">
                      {listing?.data?.landlord?.firstName
                        ? listing?.data?.landlord?.firstName
                        : '--'}{' '}
                      {listing?.data?.landlord?.lastName
                        ? listing?.data?.landlord?.lastName
                        : '--'}
                    </h3>
                  </span>
                </div>
                <div className="flex gap-3 items-center my-3">
                  <MdPhone />
                  <span>
                    {listing?.data?.landlord?.phone
                      ? listing?.data?.landlord?.phone
                      : '--'}
                  </span>
                </div>
                <div className="flex gap-3 items-center mb-3">
                  <MdOutlineEmail />
                  <span>
                    {listing?.data?.landlord?.email
                      ? listing?.data?.landlord?.email
                      : '--'}
                  </span>
                </div>
              </div>
              <div>
                <Link
                  to={`/landlords/${listing?.data?.id}`}
                  className="text-sm inline-block text-white px-3 py-3 bg-blue rounded-md"
                >
                  View Profile
                </Link>
              </div>
              <h3 className="text-lg font-bold mt-6 mb-2">Tenant Preference</h3>
              <PropertyPreference
                familiesAllowed={listing?.data?.familiesAllowed}
                workingClass={listing?.data?.workingClass}
                studentAllowed={listing?.data?.studentAllowed}
                petsAllowed={listing?.data?.petsAllowed}
                smokersAllowed={listing?.data?.smokersAllowed}
              />
              <p className="text-lg font-bold mt-6 mb-2">Amenities</p>
              <PropertyChecklist
                waterHeater={listing?.data?.waterHeater}
                estateHousing={listing?.data?.estateHousing}
                internetService={listing?.data?.internetService}
                solarInverter={listing?.data?.solarInverter}
                sportsArea={listing?.data?.sportsArea}
                security={listing?.data?.security}
                wasteDisposalService={listing?.data?.wasteDisposalService}
                parkingSpace={listing?.data?.parkingSpace}
              />
              <div className="text-lg font-bold my-3">Document(s)</div>
              <div className="flex gap-2 justify-between">
                {listing?.data?.verificationMethod && (
                  <p
                    className="text-sm inline-block -xs text-white px-3 py-3 bg-blue rounded-md cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                      setContent('verification');
                    }}
                  >
                    Verification Method
                  </p>
                )}
                {listing?.data?.proofOfOwnership && (
                  <p
                    className="text-sm inline-block -xs text-white px-3 py-3 bg-blue rounded-md cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                      setContent('proof');
                    }}
                  >
                    Proof of Ownership
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PropertyVerificationPreview;
