import React from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import OverallCount from './OverallCount';
import PropertyAnalyticsCharts from './PropertyAnalyticsCharts';
import FinancialAnalyticsCharts from './FinancialAnalyticsCharts';
import { useGetDashboardDataQuery } from '../../hooks/api/Dashboard/useGetDashboardDataQuery';
import { useGetAllPropertyListingQuery } from '../../hooks/api/Listing/useGetAllPropertyListingQuery';
import ListView from '../Listings/ListView';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';

type Props = {};

const Overview = (props: Props) => {
  const { data, isFetching } = useGetDashboardDataQuery();
  const { data: properties, isFetching: isFetchingProperties } =
    useGetAllPropertyListingQuery(1, 15, '');

  return (
    <DashboardLayout>
      <Header />
      <div>
        <OverallCount data={data} loading={isFetching} />
        <div className="overflow-y-scroll h-[70vh]">
          <div className="flex gap-4 mt-6">
            <PropertyAnalyticsCharts data={data} loading={isFetching} />
            <FinancialAnalyticsCharts />
          </div>
          <h3 className="font-bold mt-3">Recent Listings</h3>
          <div className="grid grid-cols-3 gap-4 overflow-y-scroll bg-white p-[20px] rounded-md">
            {isFetchingProperties && <LoadingComponent />}
            {properties &&
              !isFetchingProperties &&
              properties.data.data
                .slice(0, 3)
                .map(
                  ({
                    id,
                    maxNoOfTenants,
                    status,
                    approvedBy,
                    isRented,
                    address,
                    title,
                    annualRent,
                    noOfBathrooms,
                    noOfBedrooms,
                    noOfLivingRoom,
                    landlord,
                    images,
                  }) => (
                    <Link to={`/listings/${id}`} key={id}>
                      <ListView
                        maxNoOfTenants={maxNoOfTenants}
                        title={title}
                        address={address}
                        status={status}
                        images={images}
                        approvedBy={approvedBy}
                        isRented={isRented}
                        annualRent={annualRent}
                        noOfBathrooms={noOfBathrooms}
                        noOfBedrooms={noOfBedrooms}
                        noOfLivingRoom={noOfLivingRoom}
                        landlord={landlord}
                      />
                    </Link>
                  )
                )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Overview;
