import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

const PortfolioCard = ({ payload }: any) => {
  return (
    <Link to={`/portfolio/${payload?.id}`}>
      <div className="shadow-md cursor-pointer">
        <div
          className="w-full h-[200px] rounded-t-md"
          style={{
            backgroundImage: `${
              payload?.images[0] === ''
                ? '#a9a9a9'
                : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${payload?.images[0]}`
            }`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className="p-3 bg-white rounded-b-md">
          <p className="text-black font-bold">
            {payload?.title ? payload?.title : '--'}
          </p>
          <h3 className="font-medium text-gray">
            {payload?.address ? payload?.address : '--'}
          </h3>
          <NumericFormat
            value={payload?.askingPrice}
            prefix="&#8358;"
            thousandSeparator=","
            className="text-gray"
            displayType="text"
          />
        </div>
      </div>
    </Link>
  );
};

export default PortfolioCard;
