import React from 'react';
import { Spin } from 'antd';
import { useCreateAdminMutation } from '../../hooks/api/Admin/useCreateAdminMutation';
import { useFormik } from 'formik';
import { CreateAdminSchema } from '../../constants/schema';
import { useQueryClient } from 'react-query';

const AddNewAdmin = ({ handleCancel }) => {
  const { mutateAsync: createAdmin, isLoading } = useCreateAdminMutation();
  const queryClient = useQueryClient();

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: { email: '', password: '' },
      validationSchema: CreateAdminSchema,
      onSubmit: async (values) => {
        const payload = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
        };
        await createAdmin(payload, {
          onSuccess: (data) => {
            handleCancel();
            queryClient.invalidateQueries('getAdmins');
          },
        });
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-2xl font-bold text-black uppercase">
        Add new admin
      </div>
      <div>
        <label htmlFor="firstName" className="block mt-2">
          First Name
        </label>
        <input
          name="firstName"
          type="text"
          placeholder="First Name"
          className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
          value={values.firstName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      {touched.firstName && errors?.firstName ? (
        <div className="text-[#ff4444] pb-3">{errors.firstName}</div>
      ) : (
        ''
      )}

      <div>
        <label htmlFor="lastName" className="mt-2 block">
          Last Name
        </label>
        <input
          name="lastName"
          type="text"
          placeholder="Last Name"
          className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
        />
      </div>
      {touched.lastName && errors?.lastName ? (
        <div className="text-[#ff4444] pb-3">{errors.lastName}</div>
      ) : (
        ''
      )}

      <div>
        <label htmlFor="email" className="mt-2 block">
          Email Address
        </label>
        <input
          name="email"
          type="email"
          placeholder="Email address"
          className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />
      </div>
      {touched.email && errors?.email ? (
        <div className="text-[#ff4444] pb-3">{errors.email}</div>
      ) : (
        ''
      )}

      <div>
        <label htmlFor="role" className="mt-2 block">
          Role
        </label>
        <select
          name="role"
          id=""
          className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.role}
          placeholder="Select role"
        >
          <option value=""></option>
          <option value="admin">Admin</option>
          <option value="inspector">Inspector</option>
          <option value="super-admin">Super Admin</option>
        </select>
      </div>
      {touched.role && errors?.role ? (
        <div className="text-[#ff4444] pb-3">{errors.role}</div>
      ) : (
        ''
      )}

      <button
        disabled={isLoading ? true : false}
        style={{ opacity: isLoading ? 0.5 : 1 }}
        className="px-4 block mx-auto w-full bg-blue text-white py-5 rounded-md cursor-pointer mt-4"
      >
        {/* {isLoading ? <Spin size="small" /> : 'Login'} */}
        {isLoading === true ? <Spin size="small" /> : 'Add Admin'}
      </button>
    </form>
  );
};

export default AddNewAdmin;
