import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import {
  IoLocationOutline,
  IoPencilOutline,
  IoPricetagOutline,
} from 'react-icons/io5';
import { useGetOnePropertyQuery } from '../../hooks/api/Portfolio/useGetOnePropertyQuery';
import { NumericFormat } from 'react-number-format';
import { Modal, Spin } from 'antd';
import { MdVerified } from 'react-icons/md';
import BackButton from '../../components/BackButton';
import { useDeletePropertyMutation } from '../../hooks/api/Portfolio/useDeletePropertyMutation';
import ImagePreview from './ImagePreview';
import EditModal from './EditModal';

type Props = {};

const PortfolioPeview = (props: Props) => {
  const { id } = useParams();

  const { data: portfolio, isFetching } = useGetOnePropertyQuery(id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { mutateAsync: deleteProperty } = useDeletePropertyMutation();

  const Cross = require('../../assets/warningsign.png');

  const navigate = useNavigate();

  const handleDelete = () => {
    deleteProperty(id, {
      onSuccess: () => {
        navigate(-1);
      },
    });
  };

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={400}
      >
        <img src={Cross} alt="flag icon" className="flex mx-auto mt-10" />
        <div className="text-center mt-2 text-black text-2xl font-bold">
          DELETE PROPERTY
        </div>
        <p className="text-center text-gray">
          Are you sure you want to continue
        </p>
        <div className="flex mt-3 justify-center gap-4">
          <button
            className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
            onClick={() => handleDelete()}
          >
            Yes
          </button>
          <button
            className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
            onClick={() => setIsModalOpen(false)}
          >
            No
          </button>
        </div>
      </Modal>

      {portfolio && (
        <div className="flex items-center justify-between">
          <span className="flex gap-4">
            <span className="text-[#008000] font-bold">Uploaded by</span>
            <span className=" mb-3">
              <div className="font-bold flex items-center gap-1">
                <MdVerified className="text-black" />
                <p>{portfolio?.data?.principalName}</p>
              </div>
              <span className="text-gray">{}</span>
            </span>
          </span>
          <span>
            <button
              onClick={() => setIsModalOpen(true)}
              className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
            >
              Delete Entry
            </button>
          </span>
        </div>
      )}
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}

      <div className="h-[75vh] overflow-y-scroll mt-4 rounded-md">
        {portfolio && (
          <div className="flex gap-3">
            <div className="w-7/12 rounded-md p-4 bg-white">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold">Property Details</h3>
                <NumericFormat
                  value={portfolio?.data?.askingPrice}
                  prefix="&#8358;"
                  thousandSeparator=","
                  className="text-gray font-bold text-2xl"
                  displayType="text"
                />
              </div>
              <div className="my-5">
                <ImagePreview images={portfolio?.data?.images} />
              </div>
              <h3 className="font-bold text-xl">
                {portfolio?.data?.title ? portfolio?.data?.title : '--'}
              </h3>
              <div className="flex gap-3 mt-3">
                <IoPricetagOutline className=" text-xl" />
                <span className="text-gray capitalize">
                  {portfolio?.data?.tag ? portfolio?.data?.tag : '--'}
                </span>
              </div>
              <div className="flex gap-3 mt-3">
                <IoLocationOutline className=" text-xl" />
                <span className="text-gray">
                  {portfolio?.data?.address ? portfolio?.data?.address : '--'}
                </span>
              </div>
              {portfolio?.data?.description && (
                <div className="mb-3 text-black text-base">
                  <h3 className="font-bold text-black mt-3">Description</h3>
                  <p className="text-gray">{portfolio?.data?.description}</p>
                </div>
              )}
            </div>
            <div className="w-5/12">
              <div className="rounded-md p-4 bg-white">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-bold mb-3">
                    Principal's Information
                  </h3>
                  <button
                    className="font-bold text-blue cursor-pointer flex gap-2 items-center"
                    onClick={() => setEditModal(true)}
                  >
                    <IoPencilOutline />
                    <span>Edit</span>
                  </button>
                </div>

                <div className="mb-3 mt-3">
                  <label htmlFor="principalName" className="mt-2 text-gray">
                    Principal's Name
                  </label>
                  <input
                    name="principalName"
                    type="text"
                    placeholder="Principal's Name"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-black font-medium"
                    defaultValue={portfolio?.data?.principalName}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="principalPhone" className="mt-2 text-gray">
                    Principal's Phone
                  </label>
                  <input
                    name="principalPhone"
                    type="text"
                    placeholder="Principal's Phone"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-black font-medium"
                    defaultValue={portfolio?.data?.principalPhone}
                  />
                </div>
                <div className="my-4">
                  <label htmlFor="principalWhatsapp" className="mt-2 text-gray">
                    Principal's WhatsApp
                  </label>
                  <input
                    name="principalWhatsapp"
                    type="text"
                    placeholder="Principal's Phone"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-black font-medium"
                    defaultValue={portfolio?.data?.principalWhatsapp}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={editModal}
        onCancel={() => setEditModal(false)}
        footer={null}
        width={1000}
      >
        <EditModal data={portfolio?.data} />
      </Modal>
    </DashboardLayout>
  );
};

export default PortfolioPeview;
