import React from 'react';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import { PiBagBold, PiUser } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';

type Props = {
  maxNoOfTenants: string;
  status: string;
  approvedBy: any;
  isRented: boolean;
  title: string;
  address: any;
  annualRent: number;
  noOfBathrooms: any;
  noOfBedrooms: any;
  noOfLivingRoom: any;
  landlord: any;
  images: any;
};


const ListView = ({
  maxNoOfTenants,
  status,
  images,
  approvedBy,
  isRented,
  address,
  title,
  annualRent,
  noOfBathrooms,
  noOfBedrooms,
  noOfLivingRoom,
  landlord,
}: Props) => {
  let backgroundImageStyle = {};

  backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url(${images[0].imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <div className="shadow-md mb-3 rounded-md relative cursor-pointer">
      <div
        className="w-full h-[200px] rounded-t-md"
        style={backgroundImageStyle}
      ></div>
      <div className="p-2">
        <h3 className="font-bold mt-3">{title}</h3>
        <p className="text-gray">
          {address?.street && address?.street + ', '}
          {address?.city && address?.city + ', '}
          {address?.state && address?.state}
        </p>
        <span>
          <NumericFormat
            value={annualRent}
            prefix="&#8358;"
            thousandSeparator=","
            className="text-gray"
            displayType="text"
          />
        </span>
        <div className="grid grid-cols-2 text-sm mt-2">
          <span className="flex items-center gap-2 mb-2">
            <LuArmchair />
            <div className="flex-1 text-gray">
              Living Room - {noOfLivingRoom}
            </div>
          </span>
          <span className="flex items-center gap-2 mb-2">
            <LuBed />
            <div className="flex-1 text-gray">Bedrooms - {noOfBedrooms}</div>
          </span>
          <span className="flex items-center gap-2 mb-2">
            <LuBath />
            <div className="flex-1 text-gray">Bathrooms - {noOfBathrooms}</div>
          </span>
          <span className="flex items-center gap-2 mb-2">
            <PiBagBold />
            <div className="flex-1 text-gray">
              {landlord.occupation ? landlord.occupation : '--'}
            </div>
          </span>
        </div>
        <span className="flex items-center gap-2 mb-2">
          <PiUser />
          <div className=" text-gray text-sm">
            {landlord.firstName + ' ' + landlord?.lastName}
          </div>
        </span>
      </div>    </div>
  );
};

export default ListView;
