import { useMutation, useQueryClient } from 'react-query';

import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';
import toast from 'react-hot-toast';

export const useChangeAdminRoleMutation = (id, role) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      axiosInstance
        .patch(`${apiConfig.ADMIN}/${id}/change-role`, { role: role })
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success('Changed admin role');
        queryClient.invalidateQueries('getOneAdmin');
      },
      onError: (error: any) => {
        toast.error('Changed admin role');
      },
    }
  );
};
