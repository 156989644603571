import { useQuery } from 'react-query';
import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetOneRefundQuery = (id: string) => {
  return useQuery({
    queryKey: 'useGetOneRefundQuery',
    queryFn: () =>
      axiosInstance.get(`${apiConfig.REFUND}/${id}`).then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET ONE REFUND');
    },
    onError: (error: any) => {
      console.log('❌ GET ONE REFUNDS ==>', error.message);
    },
  });
};
