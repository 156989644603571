import { useQueryClient } from 'react-query';
import { useSuspendTenantMutation } from '../../hooks/api/Tenants/useSuspendTenantMutation';

type Props = { id: string; status: boolean; handleCancel: any };

const SuspendLandlord = ({ id, status, handleCancel }: Props) => {
  const { mutateAsync: suspend } = useSuspendTenantMutation();
  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault();

    suspend(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('getOneLandlord');
        setTimeout(() => {
          handleCancel();
        }, 500);
      },
    });
  };

  const Cross = require('../../assets/cross.png');

  return (
    <div>
      <img src={Cross} alt="flag icon" className="flex mx-auto mt-10" />
      <div className="text-center mt-2 text-black text-2xl font-bold">
        {status === true ? 'UNSUSPEND' : 'SUSPEND'} TENANT
      </div>
      <p className="text-center text-gray">Are you sure you want to continue</p>
      <div className="flex mt-3 justify-center gap-4">
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
          onClick={(e) => handleSubmit(e)}
        >
          Yes
        </button>
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
          onClick={handleCancel}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default SuspendLandlord;
