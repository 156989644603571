export const getStatusColor = (status) => {
  switch (status) {
    case 'success':
      return 'text-[#25ba2a]';
    case 'pending':
      return 'text-[#e27d24]';
    case 'failed':
      return 'text-[#e54c14]';
    default:
      return 'black';
  }
};
