import React, { ReactNode } from 'react';
import {
  Link,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FaRegFileAlt } from 'react-icons/fa';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { LuLayoutDashboard } from 'react-icons/lu';
import { PiCertificateBold } from 'react-icons/pi';
import { AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsHouses } from 'react-icons/bs';
import { TbLogout } from 'react-icons/tb';
import { RiTeamLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import withAuth from '../hoc/useAuth';
import { useGetMeQuery } from '../hooks/api/Admin/useGetMeQuery';

interface Props {
  children: ReactNode;
}

const Logo = require('../assets/logo1.png');

const DashboardLayout = ({ children }: Props) => {
  let location = useLocation();
  const navigate = useNavigate();

  const activeLink = 'px-3 py-2 bg-blue rounded-full text-white font-bold';
  const inActiveLink = 'px-3 py-2 bg-white text-gray1';

  const logout = () => {
    navigate('/');
    localStorage.removeItem('@me');
    localStorage.removeItem('@admin');
    localStorage.removeItem('@user');
    localStorage.removeItem('@accessToken');
    toast.success('Logged out successfully');
  };
  const { data: me } = useGetMeQuery();

  let user = localStorage.getItem('@user');

  return (
    <div className="max-w-[2000px] mx-auto">
      <p className="hidden">{JSON.stringify(me)}</p>
      <ScrollRestoration />
      <div className="md:flex w-[100%] h-[100%] gap-4 hidden">
        <div className="w-[250px] p-5 md:block hidden relative">
          <img src={Logo} alt="" className="h-[30px]" /> {JSON.stringify(user)}
          {user === 'super-admin' && (
            <div className="mt-10 text-sm">
              <Link
                to="/overview"
                className={`block mb-3
                ${
                  location?.pathname.includes('/overview')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <LuLayoutDashboard style={{ fontSize: 20 }} />
                  <span>Overview</span>
                </div>
              </Link>
              <Link
                to="/landlords"
                className={`block mb-3
                ${
                  location?.pathname.includes('/landlords')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUser style={{ fontSize: 20 }} />
                  <span>Landlords</span>
                </div>
              </Link>
              <Link
                to="/tenants"
                className={`block mb-3
                ${
                  location?.pathname.includes('/tenants')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUsergroupAdd style={{ fontSize: 20 }} />
                  <span>Tenants</span>
                </div>
              </Link>
              <Link
                to="/listings"
                className={`block mb-3
                ${
                  location?.pathname.includes('/listings')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Listings</span>
                </div>
              </Link>
              <Link
                to="/portfolio"
                className={`block mb-3
                ${
                  location?.pathname.includes('/portfolio')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Property Portfolio</span>
                </div>
              </Link>
              <Link
                to="/applications"
                className={`block mb-3
                ${
                  location?.pathname.includes('/applications')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <FaRegFileAlt style={{ fontSize: 20 }} />
                  <span>Tenant Applications</span>
                </div>
              </Link>
              <Link
                to="/verifications"
                className={`block mb-3
                ${
                  location?.pathname.includes('/verifications')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <PiCertificateBold style={{ fontSize: 20 }} />
                  <span>Property Verification</span>
                </div>
              </Link>
              <Link
                to="/transactions"
                className={`block mb-3
                ${
                  location?.pathname.includes('/transactions')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <FaMoneyBillTransfer style={{ fontSize: 20 }} />
                  <span>Transactions</span>
                </div>
              </Link>
              {user === 'super-admin' && (
                <Link
                  to="/staff"
                  className={`block mb-3
                ${
                  location?.pathname.includes('/staff')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
                >
                  <div className="flex gap-2 items-center">
                    <RiTeamLine style={{ fontSize: 20 }} />
                    <span>Internal Staff</span>
                  </div>
                </Link>
              )}
            </div>
          )}
          {user === 'admin' && (
            <div className="mt-10 text-sm">
              <Link
                to="/admin/overview"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/overview`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <LuLayoutDashboard style={{ fontSize: 20 }} />
                  <span>Overview</span>
                </div>
              </Link>
              <Link
                to="admin/landlords"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/landlords`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUser style={{ fontSize: 20 }} />
                  <span>Landlords</span>
                </div>
              </Link>
              <Link
                to="admin/tenants"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/tenants`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUsergroupAdd style={{ fontSize: 20 }} />
                  <span>Tenants</span>
                </div>
              </Link>
              <Link
                to="admin/listings"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/listings`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Listings</span>
                </div>
              </Link>
              <Link
                to="admin/portfolio"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/portfolio`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Property Portfolio</span>
                </div>
              </Link>
              <Link
                to="admin/applications"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/applications`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <FaRegFileAlt style={{ fontSize: 20 }} />
                  <span>Tenant Applications</span>
                </div>
              </Link>
              <Link
                to="admin/verifications"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/verifications`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <PiCertificateBold style={{ fontSize: 20 }} />
                  <span>Property Verification</span>
                </div>
              </Link>
              <Link
                to="admin/transactions"
                className={`block mb-3
                ${
                  location?.pathname.includes(`admin/transactions`)
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <FaMoneyBillTransfer style={{ fontSize: 20 }} />
                  <span>Transactions</span>
                </div>
              </Link>
            </div>
          )}
          {user === 'inspector' && (
            <div className="mt-10 text-sm">
              <Link
                to="inspector/overview"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/overview')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <LuLayoutDashboard style={{ fontSize: 20 }} />
                  <span>Overview</span>
                </div>
              </Link>
              <Link
                to="inspector/landlords"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/landlords')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUser style={{ fontSize: 20 }} />
                  <span>Landlords</span>
                </div>
              </Link>
              <Link
                to="inspector/tenants"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/tenants')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <AiOutlineUsergroupAdd style={{ fontSize: 20 }} />
                  <span>Tenants</span>
                </div>
              </Link>
              <Link
                to="inspector/listings"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/listings')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Listings</span>
                </div>
              </Link>
              <Link
                to="inspector/portfolio"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/portfolio')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <BsHouses style={{ fontSize: 20 }} />
                  <span>Property Portfolio</span>
                </div>
              </Link>
              <Link
                to="inspector/applications"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/applications')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <FaRegFileAlt style={{ fontSize: 20 }} />
                  <span>Tenant Applications</span>
                </div>
              </Link>
              <Link
                to="inspector/verifications"
                className={`block mb-3
                ${
                  location?.pathname.includes('inspector/verifications')
                    ? `${activeLink}`
                    : `${inActiveLink}`
                }`}
              >
                <div className="flex gap-2 items-center">
                  <PiCertificateBold style={{ fontSize: 20 }} />
                  <span>Property Verification</span>
                </div>
              </Link>
            </div>
          )}
          <div
            className="px-3 py-2  text-gray1 absolute bottom-0 bg-white cursor-pointer"
            onClick={() => logout()}
          >
            <div className="flex gap-2 items-center">
              <TbLogout />
              <span>Logout</span>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-[#F7F9F9] h-[100vh] p-[30px] overflow-y-hidden">
          {children}
        </div>
      </div>
      <div className="md:hidden block">
        <div className="text-center mt-[20vh]">
          NOT AVAILABLE FOR SMALL SCREEN DEVICE(S), KINDLY SIGN IN ON A LARGE
          SCREEN DEVICE
        </div>
      </div>
    </div>
  );
};

export default withAuth(DashboardLayout);
