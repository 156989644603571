import React, { useEffect, useState } from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { useChangeAdminRoleMutation } from '../../../hooks/api/Admin/useChangeAdminRoleMutation';

type Props = { payload: any; handleCancel: any };

const RolesModal = ({ payload, handleCancel }: Props) => {
  const [activeUser, setActiveUser] = useState('');

  const [defaultRole, setDefaultRole] = useState('');

  useEffect(() => {
    if (payload?.data?.roles.includes('super-admin')) {
      setActiveUser('super-admin');
    } else if (payload?.data?.roles.includes('admin')) {
      setActiveUser('admin');
    } else if (payload?.data?.roles.includes('inspector')) {
      setActiveUser('inspector');
    } else {
      setActiveUser('');
    }
  }, [payload?.data?.roles]);

  useEffect(() => {
    if (payload?.data?.roles.includes('super-admin')) {
      setDefaultRole('super-admin');
    } else if (payload?.data?.roles.includes('admin')) {
      setDefaultRole('admin');
    } else if (payload?.data?.roles.includes('inspector')) {
      setDefaultRole('inspector');
    } else {
      setDefaultRole('');
    }
  }, [payload?.data?.roles]);

  const { mutateAsync: changeRole } = useChangeAdminRoleMutation(
    payload?.data?.id,
    activeUser
  );

  return (
    <div>
      <h3 className="text-black font-bold text-xl uppercase">Change Role</h3>
      <div
        className={`my-3 p-3 rounded-md flex justify-between items-center border-[2px] ${
          activeUser === 'super-admin' ? 'border-blue' : 'border-[#bcbcbc]'
        }`}
      >
        <h3 className="font-bold">Super Admin</h3>
        {activeUser === 'super-admin' ? (
          <ImCheckboxChecked fontSize={20} className="text-blue" />
        ) : (
          <ImCheckboxUnchecked
            fontSize={20}
            className="text-[#bcbcbc] cursor-pointer"
            onClick={() => {
              setActiveUser('super-admin');
            }}
          />
        )}
      </div>
      <div
        className={`my-3 p-3 rounded-md flex justify-between items-center border-[2px] ${
          activeUser === 'admin' ? 'border-blue' : 'border-[#bcbcbc]'
        }`}
      >
        <h3 className="font-bold">Admin</h3>
        {activeUser === 'admin' ? (
          <ImCheckboxChecked
            fontSize={20}
            className="text-blue cursor-pointer"
          />
        ) : (
          <ImCheckboxUnchecked
            fontSize={20}
            className="text-[#bcbcbc] cursor-pointer"
            onClick={() => {
              setActiveUser('admin');
            }}
          />
        )}
      </div>
      <div
        className={`my-3 p-3 rounded-md flex justify-between items-center border-[2px] ${
          activeUser === 'inspector' ? 'border-blue' : 'border-[#bcbcbc]'
        }`}
      >
        <h3 className="font-bold">Inspector</h3>
        {activeUser === 'inspector' ? (
          <ImCheckboxChecked
            fontSize={20}
            className="text-blue cursor-pointer"
          />
        ) : (
          <ImCheckboxUnchecked
            fontSize={20}
            className="text-[#bcbcbc] cursor-pointer"
            onClick={() => {
              setActiveUser('inspector');
            }}
          />
        )}
      </div>
      <button
        disabled={payload?.data?.roles === activeUser}
        onClick={() => {
          changeRole();
          handleCancel();
        }}
        className={`px-4 block mx-auto w-full bg-blue text-white py-5 rounded-md  mt-4 ${
          defaultRole === activeUser
            ? 'cursor-not-allowed opacity-50'
            : 'cursor-pointer'
        }`}
      >
        Save changes
      </button>
    </div>
  );
};

export default RolesModal;
