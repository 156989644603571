import { apiConfig } from '../../../config/apiConfig';
import { useFetchPaginatedData } from '../useFetchPaginatedData';

export const useGetTenantsQuery = (page = 1, limit = 9, query) => {
  console.log("HERE'S THE DATA", page, limit, query);
  return useFetchPaginatedData(
    apiConfig.TENANTS,
    'getTenants',
    page,
    limit,
    query
  );
};
