import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

interface ImagePreviewProps {
  images: string[];
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ images }) => {
  if (images.length === 0) {
    return (
      <div style={{ backgroundColor: 'grey', height: '400px' }}>
        No images available
      </div>
    );
  } else if (images.length === 1) {
    const imageUrl = images[0];

    return (
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '400px',
        }}
      />
    );
  } else {
    return (
      <Slide>
        {images.map((img, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${img})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: '400px',
            }}
          ></div>
        ))}
      </Slide>
    );
  }
};

export default ImagePreview;
