import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { useGetLandlordsQuery } from '../../hooks/api/Landlords/useGetLandlordsQuery';
import LandlordCard from './LandlordCard';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import LoadingComponent from '../../components/LoadingComponent';

const Landlords = () => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data: landlords, isFetching } = useGetLandlordsQuery(
    currentPage,
    15,
    searchParam
  );

  const pages = Array.from(
    { length: landlords?.data?.last_page },
    (_, index) => index + 1
  );

  type LandlordCardProps = {
    id: string;
    email: string;
    image: string;
    isEmailVerified: boolean;
    isProfileComplete: boolean;
    suspend: boolean;
    isFlagged: boolean;
    deleted: boolean;
    roles: string[];
    firstName: string;
    lastName: string;
    lga: string;
    moreDescription: string;
    nextOfKinName: string;
    nextOfKinPhone: string;
    occupation: string;
    phone: string;
    state: string;
    otp?: any;
  };

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold">Landlords</span>

            {landlords && (
              <span className="bg-[#4D4D94] text-white text-xs p-1 rounded-md">
                {landlords?.data?.total}
              </span>
            )}
          </div>
          <p className="text-gray mt-2">
            View Landlord{landlords?.data?.total > 1 && 's'}
          </p>
        </span>
        <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      {isFetching && <LoadingComponent />}

      <div className="grid grid-cols-3 gap-5 h-[65vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {landlords?.data.data &&
          !isFetching &&
          landlords?.data.data.map((payload: LandlordCardProps) => (
            <div key={payload.id}>
              <LandlordCard payload={payload} />
            </div>
          ))}
      </div>
      <div className="h-[7vh] flex justify-between items-center">
        <span className="text-gray">
          Showing page {currentPage} of {landlords?.data?.last_page}
        </span>
        <span className="flex gap-3 items-center">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={landlords?.data?.current_page === 1 ? true : false}
            className={` ${
              landlords?.data?.current_page === 1
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            <FaChevronCircleLeft />
          </button>
          {pages.map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              disabled={currentPage === page}
              className={`border-[1px] rounded-md p-2 border-black text-xs ${
                currentPage === page
                  ? ' cursor-not-allowed bg-blue text-white'
                  : ''
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === landlords?.data?.last_page}
            className={`${
              currentPage === landlords?.data?.last_page ? 'opacity-50' : ''
            }`}
          >
            <FaChevronCircleRight />
          </button>
        </span>
      </div>
    </DashboardLayout>
  );
};

export default Landlords;
