import { apiConfig } from '../../../config/apiConfig';
import { useFetchPaginatedData } from '../useFetchPaginatedData';

export const useGetAdminsQuery = (page = 1, limit = 15, query) => {
  return useFetchPaginatedData(
    apiConfig.ADMIN,
    'getAdmins',
    page,
    limit,
    query
  );
};
