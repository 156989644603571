import { apiConfig } from '../../../config/apiConfig';
import { useFetchPaginatedData } from '../useFetchPaginatedData';

export const useGetTransactionsQuery = (page = 1, limit = 15, query) => {
  return useFetchPaginatedData(
    apiConfig.TRANSACTIONS,
    'getTransactions',
    page,
    limit,
    query
  );
};
