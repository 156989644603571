import { useQueryClient } from 'react-query';
import { useListListingMutation } from '../../hooks/api/Listing/useListListingMutation';

type Props = { id: string; status: boolean; handleCancel: any };

const UnlistListing = ({ id, status, handleCancel }: Props) => {
  const { mutateAsync: list } = useListListingMutation();
  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault();

    list(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('getOneListing');
        setTimeout(() => {
          handleCancel();
        }, 500);
      },
    });
  };

  const Cross = require('../../assets/cross.png');

  return (
    <div>
      <img src={Cross} alt="flag icon" className="flex mx-auto mt-10" />
      <div className="text-center mt-2 text-black text-2xl font-bold">
        {status === true ? 'UNLIST' : 'LIST'} LISTING
      </div>
      <p className="text-center text-gray">Are you sure you want to continue</p>
      <div className="flex mt-3 justify-center gap-4">
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
          onClick={(e) => handleSubmit(e)}
        >
          Yes
        </button>
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
          onClick={handleCancel}
        >
          No
        </button>
      </div>

      {/* <button
        type="submit"
        onClick={(e) => handleSubmit(e)}
        disabled={email === inputData ? false : true}
        style={{ opacity: isLoading ? 0.5 : 1 }}
        className={`px-4 block mx-auto w-full  text-white py-5 rounded-md cursor-pointer mt-4 font-bold ${
          email === inputData
            ? 'bg-blue cursor-pointer'
            : 'bg-[#ddd] cursor-not-allowed'
        }`}
      >
        {isLoading ? <Spin size="small" /> : 'Submit'}
      </button> */}
    </div>
  );
};

export default UnlistListing;
