import React, { useState } from 'react';
import { useFormik } from 'formik';

import { useNavigate } from 'react-router-dom';
import { SigninSchema } from '../../constants/schema';
import { useSigninMutation } from '../../hooks/api/useSigninMutation';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Spin } from 'antd';
import bgVideo from '../../assets/rentalybg.mp4';

const SignIn = () => {
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(true);

  const { mutateAsync: signin, isLoading } = useSigninMutation();

  // useEffect(() => {
  //   // Check if the user is signed in and redirect accordingly
  //   const userRole = localStorage.getItem('@user');

  //   if (userRole) {
  //     switch (userRole) {
  //       case 'super-admin':
  //         navigate('/super-admin/overview');
  //         break;
  //       case 'admin':
  //         navigate('/admin/overview');
  //         break;
  //       case 'inspector':
  //         navigate('/inspector/overview');
  //         break;
  //       default:
  //         navigate('/');
  //         break;
  //     }
  //   }
  // }, []);

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: { email: '', password: '' },
      validationSchema: SigninSchema,
      onSubmit: async (values) => {
        const payload = {
          email: values.email,
          password: values.password,
        };
        await signin(payload, {
          onSuccess: (data) => {
            localStorage.setItem('@admin', JSON.stringify(data?.data));
            localStorage.setItem(
              '@accessToken',
              JSON.stringify(data?.data?.accessToken)
            );
            navigate('/overview');

            // if (data?.data?.roles.includes('super-admin')) {
            //   localStorage.setItem('@user', 'super-admin');
            //   navigate(`/super-admin/overview`);
            // } else if (data?.data?.roles.includes('admin')) {
            //   localStorage.setItem('@user', 'admin');
            //   navigate(`/admin/overview`);
            // } else if (data?.data?.roles.includes('inspector')) {
            //   localStorage.setItem('@user', 'inspector');
            //   navigate(`/inspector/overview`);
            // } else {
            //   localStorage.setItem('@user', '');
            // }
          },
        });
      },
    });

  return (
    <div>
      <div className="homeBanner md:block hidden">
        <video
          src={bgVideo}
          autoPlay
          loop
          muted
          style={{ height: '100vh', width: '100vw', padding: 0, margin: 0 }}
        />
        <form
          onSubmit={handleSubmit}
          className="w-[500px] absolute top-[50vh] left-[50vw] -translate-y-[50%] -translate-x-[50%] p-[40px] glass-effect"
        >
          <label htmlFor="email" className="font-gray">
            Email
          </label>
          <input
            name="email"
            type="email"
            placeholder="Email"
            className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            autoFocus
          />
          {touched.email && errors?.email ? (
            <div className="text-[#ff4444] pb-3">{errors.email}</div>
          ) : (
            ''
          )}

          <label htmlFor="propertytype" className="font-gray">
            Password
          </label>
          <div className="relative">
            <input
              name="password"
              type={visibility === false ? 'text' : 'password'}
              placeholder="********"
              className="px-6 py-4 mt-3 rounded-md w-full cursor-pointer bg-slate-100"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              disabled={isLoading}
            />
            <span
              className="text-[24px] text-gray-500 cursor-pointer absolute right-2 top-7"
              onClick={() => setVisibility(!visibility)}
            >
              {visibility === true ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </span>
          </div>
          {touched.password && errors?.password ? (
            <div className="text-[#ff4444] pb-3">{errors.password}</div>
          ) : (
            ''
          )}

          <button
            type="submit"
            disabled={isLoading ? true : false}
            style={{ opacity: isLoading ? 0.5 : 1 }}
            className="px-4 block mx-auto w-full bg-blue text-white py-5 rounded-md cursor-pointer mt-4"
          >
            {isLoading ? <Spin size="small" /> : 'Login'}
          </button>
        </form>
      </div>
      <div className="md:hidden block">
        <div className="text-center mt-[20vh]">
          NOT AVAILABLE FOR SMALL SCREEN DEVICE(S), KINDLY SIGN IN ON A LARGE
          SCREEN DEVICE
        </div>
      </div>
    </div>
  );
};

export default SignIn;
