import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useUploadImageMutation } from '../hooks/api/Upload/useUploadImageMutation';

type Props = {
  setUserData: any;
  userData: any;
};

const Playground = ({ setUserData, userData }: Props) => {
  const [images, setImages] = useState<{ file: File; url: string }[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState<FormData | null>(null);

  const { mutateAsync: uploadImageToApi } = useUploadImageMutation();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    const selectedImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));

    setImages((prevImages) => [...prevImages, ...selectedImages]);

    const data = new FormData();
    selectedImages.forEach((image, index) => {
      data.append(`files`, image.file);
    });
    setFormData(data);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData) return;

    console.log('---RUNNING MULTIPLE UPLOADS---', formData);
    setIsUploading(true);

    try {
      if (formData) {
        const res = uploadImageToApi(formData, {
          onSuccess: (data) => {
            console.log('response from upload api ==>', data);
            setIsUploading(false);
            setUserData({ ...userData, images: data?.data });
          },
        });
        return res;
      }
    } catch (error) {
      console.log('error from upload api ==>', error);
      setIsUploading(false);
    }
  };

  const handleDeleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((image, i) => i !== index));
  };

  return (
    <form onSubmit={handleSubmit} className="pt-[10vh]">
      <input
        type="file"
        onChange={handleImageChange}
        multiple
        accept=".png, .jpeg, .jpg"
      />

      <div className="flex overflow-x-scroll">
        {images.map((image, index) => (
          <div key={index} className="flex-none mx-2">
            <h4 className="font-bold text-xs mb-2">{image?.file?.name}</h4>
            <img
              src={image.url}
              alt={`${index}`}
              className="h-[150px] w-[200px] object-cover"
            />
            <button
              type="button"
              onClick={() => handleDeleteImage(index)}
              className="text-red-500 font-bold mt-3"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      {isUploading && (
        <p className="text-center text-gray py-[10px]">Uploading</p>
      )}
      <button type="submit" className="p-2 bg-blue mt-2 rounded-md text-white">
        Upload
      </button>
    </form>
  );
};

export default Playground;
