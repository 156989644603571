import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

type Props = {
  id: string;
  tenant: any;
  listing: any;
  createdAt: string;
  status: string;
};

const Application = ({ id, tenant, listing, createdAt, status }: Props) => {
  const Avatar = require('../../assets/avatar.jpg');

  return (
    <div className="flex gap-2 items-center text-sm my-2 pb-2 border-b-[1px] border-[#ddd] p-2">
      <div className="w-3/12 flex gap-3 items-center">
        <img
          src={tenant.image !== '' ? tenant.image : Avatar}
          alt="avatar"
          className="h-[40px] w-[40px] rounded-full"
        />
        <div>
          <h3>{tenant?.firstName + ' ' + tenant?.lastName}</h3>
          <p className="text-gray">{tenant?.occupation}</p>
        </div>
      </div>
      <div className="w-4/12">
        <h3 className="capitalize">{listing?.propertyType}</h3>
        <p className="text-xs text-gray">
          {listing?.addressLine1 && listing?.addressLine1 + ', '}
          {listing?.addressLine2 && listing?.addressLine2 + ', '}
          {listing?.city && listing?.city + ', '}
          {listing?.state && listing?.state}
        </p>
      </div>
      <div className="w-2/12">Unrated</div>
      <div className="w-2/12">
        <Moment format="YYYY/MM/DD" interval={0}>
          {createdAt}
        </Moment>
      </div>

      <div className="w-1/12">
        {status === 'approved_application' && (
          <span className="text-[#25ba2a]">Approved</span>
        )}
        {status === 'rejected_application' && (
          <span className="text-[#e54c14]">Rejected</span>
        )}
        {status === 'pending' && (
          <span className="text-[#e27d24]">Pending</span>
        )}
      </div>
      <div className="w-1/12">
        <Link
          to={`/applications/${id}`}
          className="px-8 block bg-blue text-white py-2 rounded-md cursor-pointer text-sm"
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default Application;
