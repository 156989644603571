import { createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const me = JSON.parse(localStorage.getItem('@me'));
  const user = localStorage.getItem('@user');


  return (
    <AppContext.Provider
      value={{
        me,
        user,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
