import { useMutation } from 'react-query';

import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';
import toast from 'react-hot-toast';

export const useSuspendAdminMutation = () => {
  return useMutation(
    (id: string[]) =>
      axiosInstance
        .patch(`${apiConfig.SUSPEND}`, { ids: id })
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success('Suspended successfully');
        console.log('✅ useSuspendAdminMutation');
      },
      onError: (error: any) => {
        toast.error('Failed to suspend');
        console.log('❌ useSuspendAdminMutation');
      },
    }
  );
};
