import React from 'react';
import { IoCaretBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className="flex gap-1 items-center text-sm"
      onClick={() => navigate(-1)}
    >
      <IoCaretBack />
      <span>Back</span>
    </button>
  );
};

export default BackButton;
