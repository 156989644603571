import React from 'react';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { IoLocationOutline } from 'react-icons/io5';
import { Spin } from 'antd';
import { NumericFormat } from 'react-number-format';
import { useGetOneTransactionQuery } from '../../hooks/api/Transactions/useGetOneTransactionQuery';
import BackButton from '../../components/BackButton';
import ImageGallery from '../../components/ImageGallery';

const TransactionsPreview = () => {
  const { id } = useParams();

  const {
    data: transaction,
    isLoading,
    isFetching,
  } = useGetOneTransactionQuery(id);

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      {isLoading ||
        (isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />)}
      <div className="h-[75vh] overflow-y-scroll mt-4 rounded-md">
        {transaction && (
          <div className="flex gap-3">
            <div className="w-7/12 rounded-md p-4 bg-white">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold">Property Details</h3>
                <NumericFormat
                  value={transaction?.data?.askingPrice}
                  prefix="&#8358;"
                  thousandSeparator=","
                  className="text-gray font-bold text-2xl"
                  displayType="text"
                />
              </div>
              <div className="my-5">
                <ImageGallery images={transaction?.data?.listing?.images} />
              </div>
              <h3 className="font-bold text-xl">
                {transaction?.data?.title ? transaction?.data?.title : '--'}
              </h3>
              <div className="flex gap-3 mt-3">
                <IoLocationOutline className=" text-base" />
                <span className="text-gray">
                  {transaction?.data?.listing?.addressLine1 !== '' &&
                    transaction?.data?.listing?.addressLine1}{' '}
                  {transaction?.data?.listing?.addressLine2 !== '' &&
                    transaction?.data?.listing?.addressLine2}
                </span>
              </div>

              <h3 className="font-bold text-lg mt-3">Description</h3>
              <p className="text-gray">
                {transaction?.data?.listing?.description
                  ? transaction?.data?.listing?.description
                  : '--'}
              </p>
            </div>
            <div className="w-5/12">
              <div className="rounded-md p-4 bg-white">
                <h3 className="text-lg font-bold mb-3">Transaction Details</h3>
                <div className="flex justify-between mb-3 text-black text-md">
                  <span>Sender</span>
                  <span className="text-gray">
                    {transaction?.data?.sender
                      ? transaction?.data?.sender
                      : '--'}
                  </span>
                </div>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Beneficiary</span>
                  <span className="text-gray">
                    {transaction?.data?.recipient
                      ? transaction?.data?.recipient
                      : '--'}
                  </span>
                </div>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Payment ID</span>
                  <span className="text-gray">
                    {transaction?.data?.reference
                      ? transaction?.data?.reference
                      : '--'}
                  </span>
                </div>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Date</span>
                  <span className="text-gray">
                    {transaction?.data?.reference
                      ? transaction?.data?.reference
                      : '--'}
                  </span>
                </div>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Status</span>
                  <span className="text-gray">
                    {transaction?.data?.status
                      ? transaction?.data?.status
                      : '--'}
                  </span>
                </div>
                <hr style={{ borderTop: '1px solid #ddd' }} />
                <h3 className="text-base font-bold my-3">Payment Details</h3>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Amount Paid</span>
                  <NumericFormat
                    value={
                      transaction?.data?.balanceBefore -
                      transaction?.data?.balanceAfter
                    }
                    prefix="&#8358;"
                    thousandSeparator=","
                    className="text-gray"
                    displayType="text"
                  />
                </div>
                <div className="flex justify-between mb-3 text-black text-base">
                  <span>Description</span>
                  <span className="text-gray">
                    {transaction?.data?.note ? transaction?.data?.note : '--'}
                  </span>
                </div>
              </div>
              <p className="py-4"></p>
              <button className="bg-blue rounded-md text-white p-3 text-md mt-4">
                Export Receipt
              </button>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default TransactionsPreview;
