import React from 'react';
import { RiCheckboxBlankFill, RiCheckboxBlankLine } from 'react-icons/ri';

type Props = {
  waterHeater: boolean;
  estateHousing: boolean;
  internetService: boolean;
  solarInverter: boolean;
  sportsArea: boolean;
  security: boolean;
  wasteDisposalService: boolean;
  parkingSpace: boolean;
};

const ListingChecklist = ({
  waterHeater,
  estateHousing,
  internetService,
  solarInverter,
  sportsArea,
  security,
  wasteDisposalService,
  parkingSpace,
}: Props) => {
  const data = {
    waterHeater: waterHeater,
    estateHousing: estateHousing,
    internetService: internetService,
    solarInverter: solarInverter,
    sportsArea: sportsArea,
    security: security,
    wasteDisposalService: wasteDisposalService,
    parkingSpace: parkingSpace,
  };
  return (
    <div className="grid grid-cols-2 text-gray cursor-none">
      {Object.entries(data).map(([key, value]) => (
        <div className="flex gap-2 items-center mb-2" key={key}>
          {value ? (
            <RiCheckboxBlankFill
              className="text-blue cursor-pointer"
              fontSize={20}
            />
          ) : (
            <RiCheckboxBlankLine
              fontSize={20}
              className="text-blue cursor-pointer"
            />
          )}
          <span className="text-xs">
            {key === 'waterHeater' && 'Water Heater'}
            {key === 'estateHousing' && 'Estate Housing'}
            {key === 'internetService' && 'Internet Service'}
            {key === 'solarInverter' && 'Solar/Inverter'}
            {key === 'sportsArea' && 'Sport Area'}
            {key === 'security' && 'Security'}
            {key === 'wasteDisposalService' && 'Waste Disposal Service'}
            {key === 'parkingSpace' && 'Parking Space'}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ListingChecklist;
