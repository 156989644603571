import React from 'react';
import { BsFlag } from 'react-icons/bs';
import {
  ImCalendar,
  ImCheckboxChecked,
  ImCheckboxUnchecked,
} from 'react-icons/im';
import { Link } from 'react-router-dom';
import { RiUserForbidLine } from 'react-icons/ri';
import Moment from 'react-moment';

const StaffCard = ({ payload, clicked, clickAction }: any) => {
  const Avatar = require('../../assets/avatar.jpg');

  return (
    <div className="shadow-md rounded-lg">
      {/* <p>{payload?.id}</p> */}
      <div className="p-4 flex gap-2 items-center bg-[#f7f7f7] rounded-t-md border-b-[1px] border-[#00000033]">
        <img
          src={payload?.image ? payload?.image : Avatar}
          alt="avatar"
          className="h-[60px] w-[60px] rounded-full"
        />
        <span className="grow">
          <Link to={`/staff/${payload?.id}`}>
            <div className="text-black font-bold">
              {payload?.firstName ? payload?.firstName : '--'}{' '}
              {payload?.lastName ? payload?.lastName : '--'}
            </div>
          </Link>
          <div className="text-gray text-md">
            {payload?.roles ? payload?.roles : '--'}
          </div>
          <div className="text-gray text-xs">
            Last Login:{' '}
            {payload?.lastLogin ? (
              <Moment format="YYYY/MM/DD" interval={0}>
                {payload?.lastLogin}
              </Moment>
            ) : (
              '--'
            )}
          </div>
        </span>
        {!payload?.deleted && (
          <span onClick={clickAction} className="cursor-pointer">
            {clicked ? (
              <ImCheckboxChecked fontSize={20} className="text-blue" />
            ) : (
              <ImCheckboxUnchecked fontSize={20} className="text-blue" />
            )}
          </span>
        )}
        <div>
          {payload?.deleted && (
            <span className="bg-[#fa3838] p-2 rounded-md text-white text-xs">
              Deleted
            </span>
          )}
        </div>
      </div>
      <div className="px-4 pb-4 mt-4">
        <div className="flex gap-3 mb-5 mt-3 items-center">
          <RiUserForbidLine className="text-xl" />
          <span className="text-gray text-md">
            {payload?.suspendedLandlord
              ? payload?.suspendedLandlord + payload?.suspendedTenant
              : 0}{' '}
            Suspended Account
            {`${
              payload?.suspendedLandlord + payload?.suspendedTenant > 1
                ? 's'
                : ''
            }`}
          </span>
        </div>

        <div className="flex gap-3 mb-5 mt-3 items-center">
          <BsFlag className="text-xl" />
          <span className="text-gray text-md">
            {payload?.flaggedTenant
              ? payload?.flaggedTenant + payload?.flaggedLandlord
              : 0}{' '}
            Flagged Account
            {`${
              payload?.flaggedTenant + payload?.flaggedLandlord > 1 ? 's' : ''
            }`}
          </span>
        </div>
        <hr style={{ borderTop: '1px solid #ddd' }} />
        <div className="flex justify-between items-center">
          <span className="flex gap-3 mb-2 items-center mt-4 ">
            <ImCalendar className="text-xl" />
            <span className="text-gray text-md">
              {payload?.createdAt ? (
                <Moment format="YYYY/MM/DD HH:MM:SS">
                  {payload?.createdAt}
                </Moment>
              ) : (
                '--'
              )}
            </span>
          </span>
          <span>
            {payload?.suspend && (
              <span className="bg-[#f6c02c] p-2 rounded-md text-white text-xs">
                Suspended
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StaffCard;
