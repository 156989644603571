import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { useGetAdminsQuery } from '../../hooks/api/Admin/useGetAdminsQuery';
import StaffCard from './StaffCard';
import { Modal, Spin } from 'antd';
import SuspendAdmin from './SuspendAdmin';
import DeleteAdmin from './DeleteAdmin';
import AddNewAdmin from './AddNewAdmin';

const Staff = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAdmins, setSelectedAdmin] = useState([]);

  const { data: admins, isFetching } = useGetAdminsQuery(currentPage, 15, '');

  const pages = Array.from(
    { length: admins?.data?.last_page },
    (_, index) => index + 1
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleAction = (title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const selectAdmin = (adminId) => {
    const isSelected = selectedAdmins.includes(adminId);

    const updatedSelection = isSelected
      ? selectedAdmins.filter((id) => id !== adminId)
      : [...selectedAdmins, adminId];

    setSelectedAdmin(updatedSelection);
  };

  return (
    <DashboardLayout>
      <Header />
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        {content === 'new' && <AddNewAdmin handleCancel={handleCancel} />}
        {content === 'suspend' && (
          <SuspendAdmin
            selectedAdmins={selectedAdmins}
            handleCancel={handleCancel}
          />
        )}
        {content === 'delete' && (
          <DeleteAdmin id={selectedAdmins} handleCancel={handleCancel} />
        )}
      </Modal>
      <div className="flex -items-center justify-between mb-3">
        <span>
          <span className="text-xl font-bold">
            Internal Staff{' '}
            {admins && (
              <span className="bg-[#4D4D94] text-white text-sm p-1 rounded-md">
                {admins.data.total}
              </span>
            )}
          </span>
          <p className="text-gray mb-3">Manage Rentaly Internal Staff</p>
        </span>
        <span className="flex gap-4 items-center">
          <button
            onClick={() => handleAction('new')}
            className="text-white border-[2px] rounded-md border-blue bg-blue p-3"
          >
            Add New Admin
          </button>
          <button
            onClick={() => handleAction('suspend')}
            disabled={selectedAdmins.length === 0}
            className={`${
              selectedAdmins.length === 0
                ? 'text-[#d1b06e] border-[#d1b06e] bg-[#ffe5b29f]'
                : 'text-[#a4803a] border-[#CC9933] bg-[#ead3a4]'
            }   border-[2px] rounded-md  p-3`}
          >
            Suspend Admin
          </button>
          <button
            onClick={() => handleAction('delete')}
            disabled={selectedAdmins.length === 0}
            className={`${
              selectedAdmins.length === 0
                ? 'text-[#ff8787] border-[#ff8787] bg-[#ffa1a1ca]'
                : 'text-[#FF0000] border-[#FF0000] bg-[#ffa1a1ca]'
            }   border-[2px] rounded-md  p-3`}
          >
            Delete Admin
          </button>
        </span>
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      <div className="grid grid-cols-3 gap-5 h-[65vh] overflow-y-scroll bg-white p-[20px] rounded-md">
        {admins?.data.data &&
          admins?.data.data.map((payload) => (
            <div key={payload.id}>
              <StaffCard
                payload={payload}
                clicked={selectedAdmins.includes(payload.id)}
                clickAction={() => selectAdmin(payload.id)}
              />
            </div>
          ))}
      </div>
      <div className="h-[7vh] flex justify-between items-center">
        <span className="text-gray">
          Showing page {currentPage} of {admins?.data?.last_page}
        </span>
        <span className="flex gap-3 items-center">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={admins?.data?.current_page === 1 ? true : false}
            className={` ${
              admins?.data?.current_page === 1
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            <FaChevronCircleLeft />
          </button>
          {pages.map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              disabled={currentPage === page}
              className={`border-[1px] rounded-md p-2 border-black text-xs ${
                currentPage === page
                  ? ' cursor-not-allowed bg-blue text-white'
                  : ''
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === admins?.data?.last_page}
            className={`${
              currentPage === admins?.data?.last_page ? 'opacity-50' : ''
            }`}
          >
            <FaChevronCircleRight />
          </button>
        </span>
      </div>{' '}
    </DashboardLayout>
  );
};

export default Staff;
