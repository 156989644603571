import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import BackButton from '../../components/BackButton';
import { IoLocationOutline } from 'react-icons/io5';
import { BsTelephone } from 'react-icons/bs';
import { RiWhatsappFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { useAddPropertyMutation } from '../../hooks/api/Portfolio/useAddPropertyMutation';
import Playground from '../Playground';

const PortfolioUpload = () => {
  const [userData, setUserData] = useState({
    images: [''],
    title: '',
    address: '',
    description: '',
    principalName: '',
    principalPhone: '',
    principalWhatsapp: '',
    tag: '',
    noOfBedrooms: 0,
    noOfPlot: 0,
    askingPrice: 0,
  });

  const handleTextChange = (e: any) => {
    const { name, value } = e.target;

    if (
      name === 'noOfBedrooms' ||
      name === 'noOfPlot' ||
      name === 'askingPrice'
    ) {
      setUserData({ ...userData, [name]: parseInt(value, 10) });
    } else if (name === 'principalPhone' || name === 'principalWhatsapp')
      setUserData({ ...userData, [name]: '+234' + value });
    else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const { mutateAsync: addProperty } = useAddPropertyMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    addProperty(userData);
  };

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      <div className="flex gap-3 h-[80vh] overflow-y-scroll">
        <div className="w-8/12 rounded-md p-4 bg-white">
          {JSON.stringify(userData?.images[0])}
          <h3 className="text-xl text-black font-bold mb-6">
            Upload a property
          </h3>
          {/* <span className="pb-3 cursor-pointer">
            <div>
              <label htmlFor="images" className="mt-2">
                Uploaded Images
              </label>
              <input
                name="images"
                type="file"
                placeholder="Upload image"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={handleFileChange}
                multiple={true}
                ref={inputFile}
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </span> */}
          {/* <button
            className="bg-blue w-full p-2 text-center text-white"
            onClick={() => uploadImageFn(selectedImg)}
          >
            RUN UPLOAD
          </button> */}
          <Playground setUserData={setUserData} userData={userData} />
          <span className="mb-3">
            <label htmlFor="title" className="mt-2">
              Title
            </label>
            <input
              name="title"
              type="text"
              placeholder="Title"
              className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
              onChange={(e) => handleTextChange(e)}
            />
          </span>

          <div className="flex gap-3 py-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="address" className="mt-2">
                Property Address
              </label>
              <input
                name="address"
                type="text"
                placeholder="Property Address"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
              />
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalName" className="mt-2">
                Principal's name
              </label>
              <input
                name="principalName"
                type="text"
                placeholder="John Doe"
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
              />
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="tag" className="mt-2">
                Property Tag
              </label>
              <div>
                <select
                  name="tag"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                >
                  <option value="" className="w-full">
                    Select option
                  </option>
                  <option value="sale" className="w-full">
                    For Sale
                  </option>
                  <option value="lease" className="w-full">
                    For Lease
                  </option>
                </select>
              </div>
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalPhone" className="mt-2">
                Principal's Phone
              </label>
              <span className="flex gap-2 items-center">
                <span>+234</span>
                <input
                  name="principalPhone"
                  type="text"
                  placeholder="1234567890"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                />
              </span>
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <span className="mb-3 w-6/12">
              <label htmlFor="askingPrice" className="mt-2">
                Asking Price (&#8358;)
              </label>
              <input
                name="askingPrice"
                type="number"
                placeholder=""
                className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                onChange={(e) => handleTextChange(e)}
              />
            </span>
            <span className="mb-3 w-6/12">
              <label htmlFor="principalWhatsapp" className="mt-2">
                Principal's WhatsApp
              </label>
              <span className="flex gap-2 items-center">
                <span>+234</span>
                <input
                  name="principalWhatsapp"
                  type="text"
                  placeholder="1234567890"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                />
              </span>
            </span>
          </div>
          <div className="flex gap-3 pb-4">
            <div className="w-6/12">
              <div className="mb-3">
                <label htmlFor="noOfPlot" className="mt-2">
                  No. of Plots
                </label>
                <input
                  name="noOfPlot"
                  type="number"
                  placeholder=""
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="noOfBedrooms" className="mt-2">
                  No. of Bedrooms
                </label>
                <input
                  name="noOfBedrooms"
                  type="number"
                  placeholder=""
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  onChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>
            <div className="w-6/12">
              <label htmlFor="description" className="mt-2">
                Description
              </label>

              <textarea
                name="description"
                cols={30}
                rows={5}
                className="text-black bg-slate-100 p-4 resize-none rounded-md w-full my-3"
                onChange={(e) => handleTextChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="w-4/12 rounded-md p-4">
          <div className="bg-white shadow-md rounded-md">
            <div
              className="h-[200px] rounded-t-md relative"
              style={{
                backgroundImage: `${
                  userData.images[0] === ''
                    ? '#a9a9a9'
                    : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url(${userData.images[0]}`
                }`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <div className="absolute bottom-0 mb-4 ml-4 text-white">
                <NumericFormat
                  value={userData?.askingPrice}
                  prefix="&#8358;"
                  thousandSeparator=","
                  displayType="text"
                  className="font-bold"
                />
                <div className="capitalize">{userData?.tag}</div>
              </div>
            </div>
            <div className="p-3">
              <h3 className="font-bold mb-1">{userData?.title}</h3>
              <div className="mb-2 flex gap-2 capitalize">
                <IoLocationOutline className=" text-[20px]" />
                {userData?.address === '' ? (
                  <span className="p-2 bg-[#c7c7c7] w-8/12"></span>
                ) : (
                  userData?.address
                )}
              </div>
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <div className="mb-2 flex gap-2 items-center mt-3">
                <BsTelephone className=" text-xl" />
                {userData?.principalPhone === '' ? (
                  <span className="p-2 bg-[#c7c7c7] w-8/12"></span>
                ) : (
                  userData?.principalPhone
                )}
              </div>
              <Link
                to={`https://wa.me/${userData?.principalWhatsapp}?text=I'm%20interested%20in%20your%${userData?.title}%20for%${userData?.tag}`}
                target="_blank"
                className="mb-2 flex gap-2 items-center mt-3"
              >
                <RiWhatsappFill className="text-xl text-[#4caf50]" />
                <span>Chat on WhatsApp</span>
              </Link>
            </div>
          </div>

          <button
            className="text-xs text-white bg-blue px-3 py-3 rounded-md mt-10"
            onClick={(e) => handleSubmit(e)}
          >
            Publish Property
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PortfolioUpload;
