import { apiConfig } from '../../../config/apiConfig';
import { useFetchPaginatedData } from '../useFetchPaginatedData';

export const useGetAllPropertyListingQuery = (page = 1, limit = 15, query) => {
  return useFetchPaginatedData(
    apiConfig.LISTINGS,
    'getPropertyListing',
    page,
    limit,
    query
  );
};
