import React, { useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import { useGetAllPropertyListingQuery } from '../../hooks/api/Listing/useGetAllPropertyListingQuery';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { Spin } from 'antd';
import { BiSortAlt2 } from 'react-icons/bi';

const PropertyVerification = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState('');

  const renderStatus = (status: string) => {
    if (status === 'pending') {
      return 'text-orange-400';
    } else if (status === 'approved') {
      return 'text-green-400';
    } else if (status === 'denied') {
      return 'text-red-400';
    }
  };

  const { data: listings, isFetching } = useGetAllPropertyListingQuery(
    currentPage,
    15,
    searchParam
  );

  const pages = Array.from(
    { length: listings?.data?.last_page },
    (_, index) => index + 1
  );

  return (
    <DashboardLayout>
      <Header />
      <div className="flex justify-between p-3 rounded-t-md bg-white">
        <span>
          <div className="flex items-center gap-2">
            <span className="text-xl font-bold">Property Verification</span>
            <span className="bg-[#4D4D94] text-white text-sm p-1 rounded-md">
              {listings?.data?.total}
            </span>
          </div>
          <p className="text-gray mt-2">Property Awaiting Verification</p>
        </span>
        {/* <input
          type="text"
          placeholder="Search"
          className="px-6 py-4 w-[400px] mt-3 rounded-md cursor-pointer bg-slate-100"
          onChange={(e) => setSearchParam(e.target.value)}
        /> */}
        <span className="flex gap-[5px] items-center text-blue rounded-full">
          <div className="flex items-center gap-1">
            <BiSortAlt2 />
            <span>sort</span>
          </div>
          <select
            name="sort"
            className="rounded-full cursor-pointer text-sm bg-blue text-white p-2"
            value={searchParam}
            onChange={(e) => {
              setSearchParam(e.target.value);
            }}
          >
            <option value="">None</option>
            <option value="bungalow">Bungalow</option>
            <option value="duplex">Duplex</option>
            <option value="flats">Flats</option>
            <option value="mansion">Mansions</option>
            <option value="self-contain">Self Contain</option>
          </select>
        </span>
      </div>
      <div className="flex gap-2 items-center mb-2 font-bold bg-white p-2">
        <div className="w-3/12">Landlord Name</div>
        <div className="w-5/12">Property address</div>
        <div className="w-2/12">Rating</div>
        <div className="w-1/12">Date</div>
        <div className="w-1/12">Status</div>
        <div className="w-1/12"></div>
      </div>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}
      {listings?.data?.total === 0 && (
        <p className="text-center text-gray ">
          No data available for this search
        </p>
      )}

      <div className="bg-white">
        <div className="h-[60vh] overflow-y-scroll">
          {listings?.data?.data.map(
            ({
              id,
              ratingsAverage,
              propertyType,
              addressLine1,
              createdAt,
              status,
              landlord,
            }) => (
              <div
                key={id}
                className="flex gap-2 items-center text-sm my-2 pb-2 border-b-[1px] border-[#ddd] p-2"
              >
                <div className="w-3/12">
                  <div className="flex gap-2 items-center">
                    <span>
                      <h3 className="font-bold">
                        {landlord?.firstName + landlord?.lastName}
                      </h3>
                      <p className="text-gray">{landlord?.occupation}</p>
                    </span>
                  </div>
                </div>
                <div className="w-5/12">
                  <h3 className="capitalize">{propertyType}</h3>
                  <p className="text-gray text-xs">{addressLine1}</p>
                </div>
                <div className="w-2/12">
                  {ratingsAverage === 0 ? 'unrated' : ratingsAverage}
                </div>
                <div className="w-1/12 text-gray">
                  <Moment format="YYYY/MM/DD" interval={0}>
                    {createdAt}
                  </Moment>
                </div>
                <div
                  className={`${renderStatus(
                    status
                  )} capitalize  w-1/12 font-md`}
                >
                  {status}
                </div>
                <div className="w-1/12">
                  <Link
                    to={`/verifications/${id}`}
                    className="px-8 block bg-blue text-white py-2 rounded-md cursor-pointer text-sm"
                  >
                    View
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
        {listings?.data?.last_page > 1 && (
          <div className="h-[7vh] flex justify-between items-center">
            <span className="text-gray">
              Showing page {listings?.data?.current_page} of{' '}
              {listings?.data?.last_page}
            </span>
            <span className="flex gap-3 items-center">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={listings?.data?.current_page === 1 ? true : false}
                className={` ${
                  listings?.data?.current_page === 1
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`}
              >
                <FaChevronCircleLeft />
              </button>
              {pages.map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  disabled={currentPage === page}
                  className={`border-[1px] rounded-md p-2 border-black text-xs ${
                    currentPage === page
                      ? ' cursor-not-allowed bg-blue text-white'
                      : ''
                  }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === listings?.data?.last_page}
                className={`${
                  currentPage === listings?.data?.last_page ? 'opacity-50' : ''
                }`}
              >
                <FaChevronCircleRight />
              </button>
            </span>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PropertyVerification;
