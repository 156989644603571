import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetOnePropertyQuery = (id: string) => {
  return useQuery({
    queryKey: 'getOneProperty',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.PROPERTIES}/${id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ getOneProperty');
    },
    onError: (error: any) => {
      console.log('❌ getOneProperty', error.message);
    },
  });
};
