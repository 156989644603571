import React, { ReactNode } from 'react';
import { IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useGetMeQuery } from '../hooks/api/Admin/useGetMeQuery';

interface Props {
  children?: ReactNode;
}

const Header = ({ children }: Props) => {
  const { data } = useGetMeQuery();

  const Avatar = require('../assets/avatar.jpg');

  return (
    <div className="flex justify-between items-center bg-white py-[10px] px-[30px] rounded-lg mb-10 shadow-sm">
      <div className="flex gap-[20px] items-center">
        {children}
        <Link
          to="/refunds"
          className="px-4 block mx-auto w-full bg-blue text-white py-2 rounded-md cursor-pointer text-sm"
        >
          Refund Request{' '}
          <span className="bg-yellow rounded-full text-white  p-1 text-xs">
            12
          </span>
        </Link>
      </div>
      <span className="flex gap-3 items-center">
        <Link to="/profile" className="text-right cursor-pointer">
          <h3 className="font-bold text-black">
            {data?.data?.firstName} {data?.data?.lastName}
          </h3>
          <p className="text-[12px] text-black">{data?.data?.email}</p>
        </Link>
        <Link to="profile" className="text-right cursor-pointer">
          <img
            src={Avatar}
            className="rounded-full border-[2px] border-blue h-[40px] w-[40px]"
            alt="avatar"
          />
        </Link>
        <IoNotifications className="text-blue text-[30px]" />
      </span>
    </div>
  );
};

export default Header;
