import React from 'react';
import { useQueryClient } from 'react-query';
import { useSuspendAdminMutation } from '../../hooks/api/Admin/useSuspendAdminMutation';

type Props = { selectedAdmins: string[]; handleCancel: any };

const SuspendAdmin = ({ selectedAdmins, handleCancel }: Props) => {
  const Cross = require('../../assets/warningsign.png');
  const queryClient = useQueryClient();

  const { mutateAsync: suspendAdmin } = useSuspendAdminMutation();

  const handleSuspendAdmin = () => {
    suspendAdmin(selectedAdmins, {
      onSuccess(data) {
        queryClient.invalidateQueries('getAdmins');
        handleCancel();
      },
    });
  };

  return (
    <div>
      <img src={Cross} alt="flag icon" className="flex mx-auto mt-10" />
      <div className="text-center mt-2 text-black text-2xl font-bold">
        SUSPEND ADMIN{selectedAdmins.length > 1 && 'S'}
      </div>
      <p className="text-center text-gray">Are you sure you want to continue</p>
      <div className="flex mt-3 justify-center gap-4">
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
          onClick={() => handleSuspendAdmin()}
        >
          Yes
        </button>
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
          onClick={handleCancel}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default SuspendAdmin;
