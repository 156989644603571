import React, { useState } from 'react';

import { Modal, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineEmail, MdPhone, MdVerified } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';

import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import FlagListing from './FlagListing';
import UnlistListing from './UnlistListing';
import { useGetOneListingQuery } from '../../hooks/api/Listing/useGetOneListingQuery';
import { useGetOneAdminQuery } from '../../hooks/api/Admin/useGetOneAdminQuery';
import BackButton from '../../components/BackButton';
import ListingChecklist from './ListingChecklist';
import ImageGallery from '../../components/ImageGallery';

const ListingPreview = () => {
  const Avatar = require('../../assets/avatar.jpg');

  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleAction = (title) => {
    setIsModalOpen(true);
    setContent(`${title}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setContent(null);
  };

  const { data: propertyListing, isLoading } = useGetOneListingQuery(id);

  const approvedEntry = propertyListing?.data?.statusLog.find(
    (entry) => entry.status === 'approved'
  );

  const adminForApproved = approvedEntry ? approvedEntry.admin : null;
  const { data: admin } = useGetOneAdminQuery(adminForApproved);

  const activeClass = 'text-white bg-blue rounded-full bg-blue';
  const inActiveClass =
    'bg-white text-blue border-[1px] border-blue rounded-full';

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {content === 'list' && (
          <UnlistListing
            id={id}
            status={propertyListing?.data?.isListed}
            handleCancel={handleCancel}
          />
        )}
        {content === 'flag' && (
          <FlagListing
            id={id}
            status={propertyListing?.data?.isFlagged}
            handleCancel={handleCancel}
          />
        )}
        {content === 'verification' && (
          <div>
            <h3 className="font-bold">VERIFICATION METHOD</h3>
            <div>
              {propertyListing?.data?.verificationMethod &&
                propertyListing?.data?.verificationMethod.map((item, index) => (
                  <img
                    key={index}
                    className="text-black w-full my-10"
                    src={item}
                    alt="preview"
                    loading="lazy"
                  />
                ))}
            </div>
          </div>
        )}
        {content === 'proof' && (
          <div>
            <h3 className="font-bold">PROOF OF OWNERSHIP</h3>
            <div>
              {propertyListing?.data?.proofOfOwnership &&
                propertyListing?.data?.proofOfOwnership.map((item, index) => (
                  <img
                    key={index}
                    className="text-black w-full my-10"
                    src={item}
                    alt="preview"
                    loading="lazy"
                  />
                ))}
            </div>
          </div>
        )}
      </Modal>
      <div className="flex items-center justify-between">
        {propertyListing?.data?.status === 'approved' ? (
          <span className="flex gap-4">
            <span className="text-[#008000] font-bold">Approved by</span>
            <span className=" mb-3">
              <div className="font-bold flex items-center gap-2">
                <MdVerified className="text-black" />
                <p>
                  {admin &&
                    admin?.data?.firstName + ' ' + admin?.data?.lastName}
                </p>
              </div>
              <span className="text-gray">{admin?.data?.roles}</span>
            </span>
          </span>
        ) : (
          <span />
        )}
        <span>
          <div className="flex items-center justify-between mb-3">
            <span className="flex gap-4 items-center">
              <button
                onClick={() => handleAction('flag')}
                className="text-[#a4803a] border-[2px] rounded-md border-[#CC9933] bg-[#ead3a4] p-3"
              >
                {propertyListing?.data?.isFlagged === true
                  ? 'Unflagged'
                  : 'Flag'}{' '}
                Listing
              </button>
              <button
                onClick={() => handleAction('list')}
                className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
              >
                {propertyListing?.data?.isListed === true ? 'Unlist' : 'List'}{' '}
                Listing
              </button>
            </span>
          </div>
        </span>
      </div>

      {isLoading && <Spin className="mx-auto block h-full mt-[30vh]" />}

      <div className=" h-[75vh] overflow-y-scroll p-[20px] rounded-md">
        {propertyListing && (
          <div className="flex gap-4">
            <div className="w-8/12 bg-white rounded-md p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-bold">Property Details</h3>
                <div>
                  {propertyListing?.data?.annualRent ? (
                    <NumericFormat
                      value={propertyListing?.data?.annualRent}
                      prefix="&#8358;"
                      thousandSeparator=","
                      className="text-gray"
                      displayType="text"
                    />
                  ) : (
                    '--'
                  )}
                  /year
                </div>
              </div>
              <div className="my-5">
                <ImageGallery images={propertyListing?.data?.images} />
              </div>
              <h3 className="font-bold text-base capitalize">
                {propertyListing?.data?.propertyType
                  ? propertyListing?.data?.propertyType
                  : '--'}
              </h3>
              <div className="flex gap-3 mt-3">
                <IoLocationOutline className=" text-base" />
                <span className="text-gray">
                  {propertyListing?.data?.addressLine1 &&
                    propertyListing?.data?.addressLine1 + ', '}
                  {propertyListing?.data?.addressLine2 !== '' &&
                    propertyListing?.data?.addressLine2 + ', '}
                  {propertyListing?.data?.city !== '' &&
                    propertyListing?.data?.city + ', '}
                  {propertyListing?.data?.state !== '' &&
                    propertyListing?.data?.state}
                </span>
              </div>
              <h3 className="font-bold text-base mt-3">Description</h3>
              <p>
                {propertyListing?.data?.description
                  ? propertyListing?.data?.description
                  : '--'}
              </p>
            </div>

            <div className="w-4/12">
              <div className="bg-white rounded-md p-4">
                <h3 className="text-lg font-bold mb-3">Listed by</h3>
                <div className="flex gap-3">
                  <img
                    src={
                      propertyListing?.data?.landlord?.data?.image === ''
                        ? Avatar
                        : propertyListing?.data?.landlord?.data?.image
                    }
                    alt=""
                    className="h-[60px] w-[60px] rounded-full text-xs bg-ash"
                  />
                  <span>
                    <h3 className="font-bold text-black">
                      {propertyListing?.data?.landlord?.firstName
                        ? propertyListing?.data?.landlord?.firstName
                        : '--'}{' '}
                      {propertyListing?.data?.landlord?.lastName
                        ? propertyListing?.data?.landlord?.lastName
                        : '--'}
                    </h3>
                  </span>
                </div>
                <div className="flex gap-3 items-center my-3">
                  <MdPhone />
                  <span>
                    {propertyListing?.data?.landlord.phone
                      ? propertyListing?.data?.landlord.phone
                      : '--'}
                  </span>
                </div>
                <div className="flex gap-3 items-center mb-3">
                  <MdOutlineEmail />
                  <span>
                    {propertyListing?.data?.landlord.email
                      ? propertyListing?.data?.landlord.email
                      : '--'}
                  </span>
                </div>
                <Link
                  to={`/landlords/${propertyListing?.data?.landlord.id}`}
                  className="text-sm inline-block text-white px-3 py-3 bg-blue rounded-md"
                >
                  View Profile
                </Link>
              </div>
              <h3 className="text-lg font-bold mt-3">Tenant Preference</h3>
              <div className="grid gap-3 grid-cols-3 mt-4 cursor-none">
                <span
                  className={`text-center p-2 text-xs
                    ${
                      propertyListing?.data?.preferences?.familiesAllowed ===
                      true
                        ? activeClass
                        : inActiveClass
                    }
                  `}
                >
                  Family
                </span>
                <span
                  className={`text-center p-2 text-xs
                    ${
                      propertyListing?.data?.preferences?.smokersAllowed ===
                      true
                        ? activeClass
                        : inActiveClass
                    }
                  `}
                >
                  Smokers
                </span>
                <span
                  className={`text-center p-2 text-xs
                    ${
                      propertyListing?.data?.preferences?.petAllowed === true
                        ? activeClass
                        : inActiveClass
                    }
                  `}
                >
                  Pets
                </span>
                <span
                  className={`text-center p-2 text-xs
                    ${
                      propertyListing?.data?.preferences
                        ?.WorkingClassAllowed === true
                        ? activeClass
                        : inActiveClass
                    }
                  `}
                >
                  Working Class
                </span>
              </div>
              <div className="text-lg font-bold my-3">Amenities</div>
              <div>
                <ListingChecklist
                  waterHeater={propertyListing?.data?.waterHeater}
                  estateHousing={propertyListing?.data?.estateHousing}
                  internetService={propertyListing?.data?.internetService}
                  solarInverter={propertyListing?.data?.solarInverter}
                  sportsArea={propertyListing?.data?.sportsArea}
                  security={propertyListing?.data?.security}
                  wasteDisposalService={
                    propertyListing?.data?.wasteDisposalService
                  }
                  parkingSpace={propertyListing?.data?.parkingSpace}
                />
              </div>
              <div className="text-lg font-bold my-3">Document(s)</div>
              <div className="flex gap-2 justify-between">
                {propertyListing?.data?.verificationMethod && (
                  <p
                    className="text-sm inline-block -xs text-white px-3 py-3 bg-blue rounded-md cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                      setContent('verification');
                    }}
                  >
                    Verification Method
                  </p>
                )}
                {propertyListing?.data?.proofOfOwnership && (
                  <p
                    className="text-sm inline-block -xs text-white px-3 py-3 bg-blue rounded-md cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                      setContent('proof');
                    }}
                  >
                    Proof of Ownership{' '}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ListingPreview;
