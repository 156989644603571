import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetDashboardDataQuery = () => {
  return useQuery({
    queryKey: 'getDashboardData',
    queryFn: () =>
      axiosInstance.get(`${apiConfig.DASHBOARD}`).then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET DASHBOARD DATA');
    },
    onError: (error: any) => {
      console.log('❌ GET DASHBOARD DATA ==>', error.message);
    },
  });
};
