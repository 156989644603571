import React from 'react';
import { useQueryClient } from 'react-query';
import { useFlagLandlordMutation } from '../../hooks/api/Landlords/useFlagLandlordMutation';

type Props = { id: string; status: boolean; handleCancel: any };

const FlagLandlord = ({ id, status, handleCancel }: Props) => {
  const { mutateAsync: flag } = useFlagLandlordMutation();
  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault();

    flag(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('getOneLandlord');
        handleCancel();
      },
    });
  };

  const Flag = require('../../assets/flag.png');

  return (
    <div>
      <img src={Flag} alt="flag icon" className="flex mx-auto mt-10" />
      <div className="text-center mt-2 text-black text-2xl font-bold">
        {status === true ? 'UNFLAG' : 'FLAG'} LANDLORD
      </div>
      <p className="text-center text-gray">Are you sure you want to continue</p>
      <div className="flex mt-3 justify-center gap-4">
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-[#f04a4a]"
          onClick={(e) => handleSubmit(e)}
        >
          Yes
        </button>
        <button
          className="text-white px-6 py-4 rounded-md cursor-pointer font-bold bg-blue"
          onClick={handleCancel}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default FlagLandlord;
