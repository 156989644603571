import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetLandlordListingsQuery = (_id: string) => {
  return useQuery({
    queryKey: 'getLandlordListings',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.LANDLORDS}/${_id}/listings`)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ LANDLORD');
    },
    onError: (error: any) => {
      console.log('❌ LANDLORD ==>', error.message);
    },
  });
};