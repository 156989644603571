import React from 'react';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import Header from '../../components/Header';
import BackButton from '../../components/BackButton';
import { useGetOneTenantApplicationsQuery } from '../../hooks/api/Application/useGetOneTenantApplicationsQuery';
import { FiMail } from 'react-icons/fi';
import { BsTelephone } from 'react-icons/bs';
import { Spin } from 'antd';

const ApplicationPreview = () => {
  const { id } = useParams();

  const { data: application, isFetching } =
    useGetOneTenantApplicationsQuery(id);

  const Avatar = require('../../assets/avatar.jpg');

  return (
    <DashboardLayout>
      <Header>
        <BackButton />
      </Header>
      {isFetching && <Spin className="mx-auto block h-full mt-[30vh]" />}

      {application && (
        <div className="flex gap-4">
          <div className="w-5/12 rounded-md p-4 bg-white">
            <img
              src={
                application?.data?.landlord?.image !== ''
                  ? application?.data?.landlord?.image
                  : Avatar
              }
              alt="avatar"
              className="h-[80px] w-[80px] rounded-full mx-auto"
            />
            <div className="text-center text-2xl font-bold flex gap-2 items-center justify-center">
              {application?.data?.landlord?.firstName
                ? application?.data?.landlord?.firstName
                : '--'}{' '}
              {application?.data?.landlord?.lastName
                ? application?.data?.landlord?.lastName
                : '--'}
            </div>

            <p className="text-gray">Property in view</p>
            <div className="mb-3 capitalize">
              {application?.data?.listing?.propertyType}{' '}
              {application?.data?.listing?.addressLine1 !== '' &&
                application?.data?.listing?.addressLine1 + ', '}
              {application?.data?.listing?.addressLine2 !== '' &&
                application?.data?.listing?.addressLine2 + ', '}
              {application?.data?.listing?.city !==
                application?.data?.listing?.city + ', '}
              {application?.data?.listing?.state}
            </div>

            <p>Bio</p>
            <textarea
              name=""
              id=""
              cols={30}
              rows={3}
              className="text-black bg-ash p-4 resize-none rounded-md w-full mb-3"
              value={
                application?.data?.landlord?.moreDescription
                  ? application?.data?.landlord?.moreDescription
                  : '--'
              }
              disabled={true}
            />
            <hr style={{ borderTop: '1px solid #ddd' }} />
            <div className="flex gap-3 mb-5 mt-6">
              <FiMail className="mt-1 text-xl" />
              <span>
                <h4 className="text-black1">Email Address</h4>
                <span className="text-stone-500">
                  {application?.data?.landlord?.email
                    ? application?.data?.landlord?.email
                    : '--'}
                </span>
              </span>
            </div>
            <div className="flex gap-3 mb-5 mt-3">
              <BsTelephone className="mt-1 text-xl" />
              <span>
                <h4 className="text-black1">Phone Number</h4>
                <span className="text-stone-500">
                  {application?.data?.landlord?.phone
                    ? application?.data?.landlord?.phone
                    : '--'}
                </span>
              </span>
            </div>
            {/* <div className="flex gap-3 mb-5 mt-3">
                <MdOutlineAccountBalance className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Account Number</h4>
                  <span className="text-stone-500">
                    {landlord?.data?.accountNumber
                      ? landlord?.data?.accountNumber
                      : '--'}
                  </span>
                </span>
              </div> */}
            {/* <div className="flex gap-3 mb-5 mt-3">
                <MdOutlineAccountBalanceWallet className="mt-1 text-xl" />
                <span>
                  <h4 className="text-black1">Bank Code</h4>
                  <span className="text-stone-500">
                    {landlord?.data?.bankCode ? landlord?.data?.bankCode : '--'}
                  </span>
                </span>
              </div> */}
          </div>
          <div className="w-7/12 rounded-md p-4 bg-white">
            <div className="flex -items-center justify-between mb-3">
              <span>
                <span className="text-xl font-bold">Personal Details </span>
                <p className="text-gray mb-3">Manage User Information</p>
              </span>
              <span className="flex gap-4 items-center">
                {/* right */}
                {/* <button
                  onClick={() => handleAction(id, 'flag')}
                  className="text-[#a4803a] border-[2px] rounded-md border-[#CC9933] bg-[#ead3a4] p-3"
                >
                  {tenant?.data?.isFlagged === true ? 'Unflagged' : 'Flag'}{' '}
                  Tenant
                </button>
                <button
                  onClick={() => handleAction(id, 'suspend')}
                  className="text-[#FF0000] border-[2px] rounded-md border-[#FF0000] bg-[#ffa1a1ca] p-3"
                >
                  {tenant?.data?.suspend === true ? 'Unsuspend' : 'Suspend'}{' '}
                  Tenant
                </button> */}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <span className="mb-3">
                <label htmlFor="stateoforigin" className="mt-2">
                  State of Origin
                </label>
                <input
                  name="stateoforigin"
                  type="text"
                  placeholder="State of Origin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.tenant?.state
                      ? application?.data?.tenant?.state
                      : '--'
                  }
                  disabled={true}
                />
              </span>
              <span>
                <label htmlFor="nextOfKinName" className="mt-2">
                  Name of Next Kin
                </label>
                <input
                  name="nextOfKinName"
                  type="text"
                  placeholder="Name of Next of Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.tenant?.nextOfKinName
                      ? application?.data?.tenant?.nextOfKinName
                      : ''
                  }
                  disabled={true}
                />
              </span>
              <span className="mb-3">
                <label htmlFor="lga" className="">
                  Local Government
                </label>
                <input
                  name="lga"
                  type="text"
                  placeholder="Local Government"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.tenant?.lga
                      ? application?.data?.tenant?.lga
                      : '--'
                  }
                  disabled={true}
                />
              </span>
              <span>
                <label htmlFor="occupation" className="mt-2">
                  Occupation
                </label>
                <input
                  name="occupation"
                  type="text"
                  placeholder="Phone Number of Next Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.tenant?.occupation
                      ? application?.data?.tenant?.occupation
                      : '--'
                  }
                  disabled={true}
                />
              </span>
            </div>
            <hr style={{ borderTop: '1px solid #ddd' }} />
            <div className="grid grid-cols-2 gap-4 mb-6 mt-6">
              <span className="mb-3">
                <label htmlFor="name" className="mt-2">
                  Name of Referee
                </label>
                <input
                  name="name"
                  type="text"
                  placeholder="State of Origin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.reference?.name
                      ? application?.data?.reference?.name
                      : '--'
                  }
                  disabled={true}
                />
              </span>
              <span className="mb-3">
                <label htmlFor="relationship" className="mt-2">
                  Relationship with Referee
                </label>
                <input
                  name="relationship"
                  type="text"
                  placeholder="State of Origin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.reference?.relationship
                      ? application?.data?.reference?.relationship
                      : '--'
                  }
                  disabled={true}
                />
              </span>
              <span className="mb-3">
                <label htmlFor="phoneNo" className="mt-2">
                  Phone Number of Referee
                </label>
                <input
                  name="phoneNo"
                  type="text"
                  placeholder="State of Origin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    application?.data?.reference?.phoneNo
                      ? application?.data?.reference?.phoneNo
                      : '--'
                  }
                  disabled={true}
                />
              </span>
            </div>
          </div>
          {/* <div className="w-7/12 rounded-md p-4 bg-white">
            <div className="flex -items-center justify-between mb-3">
              <span>
                <span className="text-xl font-bold">Personal Details </span>
                <p className="text-gray mb-3">Manage User Information</p>
              </span>
              <span className="">
                <p>Successful</p>
                <p>DATE</p>
              </span>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <span className="mb-3">
                <label htmlFor="stateoforigin" className="mt-2">
                  State of Origin
                </label>
                <input
                  name="stateoforigin"
                  type="text"
                  placeholder="State of Origin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={tenant?.data?.state ? tenant?.data?.state : '--'}
                  disabled={true}
                />
              </span>
              <span>
                <label htmlFor="nextOfKinName" className="mt-2">
                  Name of Next Kin
                </label>
                <input
                  name="nextOfKinName"
                  type="text"
                  placeholder="Name of Next of Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    tenant?.data?.nextOfKinName
                      ? tenant?.data?.nextOfKinName
                      : ''
                  }
                  disabled={true}
                />
              </span>
              <span className="mb-3">
                <label htmlFor="lga" className="">
                  Local Government
                </label>
                <input
                  name="lga"
                  type="text"
                  placeholder="Local Government"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={tenant?.data?.lga ? tenant?.data?.lga : '--'}
                  disabled={true}
                />
              </span>
              {/* <span className="mb-3">
                  <label htmlFor="nextOfKinPhone" className="">
                    Phone Number of Next Kin
                  </label>
                  <input
                    name="nextOfKinPhone"
                    type="text"
                    placeholder="Local Government"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={
                      tenant?.data?.nextOfKinPhone
                        ? tenant?.data?.nextOfKinPhone
                        : '--'
                    }
                    disabled={true}
                  />
                </span> 
              <span>
                <label htmlFor="occupation" className="mt-2">
                  Occupation
                </label>
                <input
                  name="occupation"
                  type="text"
                  placeholder="Phone Number of Next Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    tenant?.data?.occupation ? tenant?.data?.occupation : '--'
                  }
                  disabled={true}
                />
              </span>
            </div>
            <hr style={{ borderTop: '1px solid #ddd' }} />
            {/* <div className="my-3 grid grid-cols-3 gap-4">
                <span>
                  <label htmlFor="suspend" className="mt-2">
                    Suspended
                  </label>
                  <input
                    name="suspend"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.suspend}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="isFlagged" className="mt-2">
                    Is Flagged
                  </label>
                  <input
                    name="isFlagged"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.isFlagged}
                    disabled={true}
                  />
                </span>
                <span>
                  <label htmlFor="deleted" className="mt-2">
                    Deleted
                  </label>
                  <input
                    name="deleted"
                    type="text"
                    placeholder="Name of Next of Kin"
                    className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                    value={landlord?.data?.deleted}
                    disabled={true}
                  />
                </span>
              </div> 
            <hr style={{ borderTop: '1px solid #ddd' }} />
            <div className="grid grid-cols-2 gap-4 mt-6">
              <span>
                <label htmlFor="nextOfKinName" className="mt-2">
                  Name of Next of Kin
                </label>
                <input
                  name="nextOfKinName"
                  type="text"
                  placeholder="Name of Next of Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    tenant?.data?.nextOfKinName
                      ? tenant?.data?.nextOfKinName
                      : '--'
                  }
                  disabled={true}
                />
              </span>

              <span>
                <label htmlFor="nextOfKinPhone" className="">
                  Phone Number of Next Kin
                </label>
                <input
                  name="nextOfKinPhone"
                  type="text"
                  placeholder="Phone Number of Next Kin"
                  className="px-6 py-4 mt-2 rounded-md w-full bg-slate-100 text-gray"
                  value={
                    tenant?.data?.nextOfKinPhone
                      ? tenant?.data?.nextOfKinPhone
                      : '--'
                  }
                  disabled={true}
                />
              </span>
            </div>
          </div> */}
        </div>
      )}
    </DashboardLayout>
  );
};

export default ApplicationPreview;
