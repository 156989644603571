import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetOneTransactionQuery = (id: string) => {
  return useQuery({
    queryKey: 'getOneTransaction',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.TRANSACTIONS}/${id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log('Success Getting ONE TRANSACTION Data ==>');
    },
    onError: (error: any) => {
      console.log('Failed Getting ONE TRANSACTION Data ==>', error.message);
    },
    enabled: id === null ? false : true,
  });
};
