import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useFlagTenantMutation = () => {
  return useMutation(
    (id: string) =>
      axiosInstance
        .post(`${apiConfig.TENANTS}/${id}/flag`)
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success(data?.message);
        console.log('✅ FLAGGED TENANT');
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        console.log('❌ FLAGGED TENANT');
      },
    }
  );
};
