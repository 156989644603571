import { useMutation, useQueryClient } from 'react-query';

import { axiosInstance } from '../../../utils/axiosInstance';
import { apiConfig } from '../../../config/apiConfig';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export const useDeleteAdminMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      axiosInstance
        .post(`${apiConfig.DELETE}`, {
          ids: id,
        })
        .then((res) => res.data),
    {
      onSuccess: async (data: any) => {
        toast.success('Deleted admin successfully');
        queryClient.invalidateQueries('getAdmins');
        navigate(`/staff`);
      },
      onError: (error: any) => {
        toast.error('Failed to delete admin');
      },
    }
  );
};
