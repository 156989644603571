import React from 'react';
import { BsBank, BsBriefcase, BsHouses, BsTelephone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import { TbMessageShare } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const LandlordCard = ({ payload }: any) => {
  const Avatar = require('../../assets/avatar.jpg');

  return (
    <Link to={`/landlords/${payload?.id}`}>
      <div className="shadow-md rounded-lg cursor-pointer text-base relative">
        {payload?.suspend && (
          <span className="absolute right-0 -top-2 text-xs p-2 rounded-md bg-[#4e4e4e] text-white">
            Suspended
          </span>
        )}
        <div
          className={`p-4 flex gap-2 items-center  ${
            payload?.isFlagged ? 'bg-red-600' : 'bg-[#f7f7f7]'
          } rounded-t-md border-b-[1px] border-[#00000033]`}
        >
          <img
            src={payload?.image ? payload?.image : Avatar}
            alt="avatar"
            className="h-[60px] w-[60px] rounded-full "
          />
          <span className="grow">
            <div className="text-black font-bold">
              {payload?.firstName ? payload?.firstName : '--'}{' '}
              {payload?.lastName ? payload?.lastName : '--'}
            </div>
            <span className="text-gray">Unrated</span>
          </span>
          <TbMessageShare fontSize={24} />
        </div>
        <div className="px-4 pb-4">
          <div className="flex gap-3 mb-5 mt-3 items-center">
            <FiMail className="" />
            <span className="text-gray text-md">
              {payload?.email ? payload?.email : '--'}
            </span>
          </div>
          <div className="flex gap-3 mb-5 mt-3 items-center">
            <BsTelephone className="" />
            <span className="text-gray text-md">
              {payload?.phone ? payload?.phone : '--'}
            </span>
          </div>
          <div className="flex gap-3 mb-5 mt-3 items-center">
            <BsBriefcase className="" />
            <span className="text-gray text-md">
              {payload?.occupation ? payload?.occupation : '--'}
            </span>
          </div>
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <div className="flex gap-3 mb-2 items-center mt-4">
            <BsHouses className="" />
            <span className="text-gray text-md">
              {payload?.address ? payload?.address : '--'}
            </span>
          </div>
          <div className="flex gap-3 items-top">
            <IoLocationOutline className="mt-1 " />
            <span className="text-gray text-md capitalize">
              {payload?.lga ? payload?.lga + ', ' : '--'}
              {payload?.state && payload?.state}
            </span>
          </div>
          <div className="flex gap-3 items-top mt-2">
            <BsBank className="mt-1 " />
            <span className="text-gray text-md">
              {payload?.bank ? payload?.bank : '--'}
              {payload?.accountNumber && ' | ' + payload?.accountNumber}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LandlordCard;
