import React from 'react';
import { LuArmchair, LuBath, LuBed } from 'react-icons/lu';
import { PiBagBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import { useGetOneLandlordQuery } from '../../hooks/api/Landlords/useGetOneLandlordQuery';

type Props = {
  id: string;
  propertyType: string;
  addressLine1: string;
  city: string;
  state: string;
  annualRent: number;
  noOfLivingRoom: number;
  noOfBedrooms: number;
  noOfBathrooms: number;
  landlord: string;
  status: string;
  images: any;
};

const LandlordListingCard = ({
  id,
  propertyType,
  addressLine1,
  city,
  state,
  annualRent,
  noOfLivingRoom,
  noOfBedrooms,
  noOfBathrooms,
  landlord,
  status,
  images,
}: Props) => {
  const { data: landlordData } = useGetOneLandlordQuery(landlord);

  return (
    <div className="shadow-md mb-3 rounded-md relative cursor-pointer p-2">
      <div>
        <img
          src={images[0].imageUrl}
          alt="property coverImage"
          className="w-full h-[200px] rounded-t-md"
        />
        <div className="p-2">
          <h3 className="font-bold mt-3 capitalize">{propertyType}</h3>
          <p className="text-gray">
            {addressLine1 && addressLine1 + ', '}
            {city && city + ', '}
            {state && state}
          </p>
          <span>
            <NumericFormat
              value={annualRent}
              prefix="&#8358;"
              thousandSeparator=","
              className="text-gray"
              displayType="text"
            />
          </span>
          <div className="grid grid-cols-2 text-sm mt-2 border-b-[1px] border-gray">
            <span className="flex items-center gap-2 mb-2">
              <LuArmchair />
              <div className="flex-1 text-gray">
                Living Room - {noOfLivingRoom}
              </div>
            </span>
            <span className="flex items-center gap-2 mb-2">
              <LuBed />
              <div className="flex-1 text-gray">Bedrooms - {noOfBedrooms}</div>
            </span>
            <span className="flex items-center gap-2 mb-2">
              <LuBath />
              <div className="flex-1 text-gray">
                Bathrooms - {noOfBathrooms}
              </div>
            </span>
            <span className="flex items-center gap-2 mb-2">
              <PiBagBold />
              <div className="flex-1 text-gray">
                {landlordData?.data?.occupation}
              </div>
            </span>
            <span className="flex items-center gap-2 mb-2">
              <img
                src={landlordData?.data?.image}
                alt=""
                className="h-[20px]"
              />
              <div className="flex-1 text-gray">
                {landlordData?.data?.firstName +
                  ' ' +
                  landlordData?.data?.lastName}
              </div>
            </span>
          </div>
          <div className="mt-2">Status: {status}</div>
        </div>
      </div>
    </div>
  );
};

export default LandlordListingCard;
