import { useQuery } from 'react-query';

import { apiConfig } from '../../../config/apiConfig';
import { axiosInstance } from '../../../utils/axiosInstance';

export const useGetOneTenantApplicationsQuery = (_id: string) => {
  return useQuery({
    queryKey: 'useGetOneTenantApplicationsQuery',
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.APPLICATION}/${_id}`)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log('✅ GET TENANT APPLICATIONS');
    },
    onError: (error: any) => {
      console.log('❌ GET TENANT APPLICATIONS ==>', error.message);
    },
  });
};
